<p></p>

<form clrForm [formGroup]="formDynamisch" (ngSubmit)="onSubmitTab($event)">
  <!-- <button class="btn btn-outline" (click)="onAddFieldClick()">
    <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
  </button> -->
  <span style="float: left; display: inline-flex">
    <!-- style="float: left; display: flex; flex-direction: column" -->
    <button
      type="submit"
      class="btn btn-outline"
      [clrLoading]="submitTabState"
      [disabled]="!inputsOk || disabled"
      style="display: inline-flex"
    >
      Werte übernehmen
    </button>
    <p
      style="color: red; display: inline-flex; margin-left: 10px"
      [style.visibility]="getVisibilityWarnings()"
    >
      (Bitte Eingaben prüfen)
    </p>
    <br />
  </span>
  <br />
  <!-- <ng-container *ngIf="templateItemArray$"> -->
  <ng-container *ngIf="getItems().length > 0" id="dropDownContainer">
    <ng-container *ngFor="let itm of getItems()" id="inputContainer">
      <ng-container *ngIf="itm.ItemType === 'Date'">
        <clr-input-container>
          <label
            class="clr-col-12 clr-col-md-3"
            for="inputContainer"
            [style]="getStyle(itm)"
          >
            {{ itm.Title }}
            <ng-container *ngIf="itm.Fixed === 3">
              <ng-container
                *ngIf="evaluationCalculations$?.containsCalculations(itm)"
              >
                | (berechnet:
                {{
                  evaluationCalculations$
                    .getCalculationFor(itm)
                    ?.value?.toFixed(2)
                }})
              </ng-container>
            </ng-container>
            <button
              *ngIf="itm.Info"
              [disabled]="!itm.Info"
              [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
              class="btn btn-sm btn-link"
              (click)="onHelperClick(itm.Title, itm.Info)"
            >
              <cds-icon shape="info-standard"></cds-icon>
            </button>
          </label>
          <input
            clrInput
            type="date"
            [formControlName]="itm._id!"
            (ngModelChange)="inputChanged()"
            style="width: 100%"
            [style]="getStyle(itm)"
            class="clr-col-12 clr-col-md-9"
            [disabled]="itm.Fixed === 3"
          />
        </clr-input-container>
      </ng-container>
      <ng-container *ngIf="itm.ItemType === 'Strings'">
        <clr-select-container>
          <label
            class="clr-col-12 clr-col-md-3"
            for="inputContainer"
            [style]="getStyle(itm)"
          >
            {{ itm.Title }}
            <ng-container *ngIf="itm.Fixed === 3">
              <ng-container
                *ngIf="evaluationCalculations$?.containsCalculations(itm)"
              >
                | (berechnet:
                {{
                  evaluationCalculations$
                    .getCalculationFor(itm)
                    ?.value?.toFixed(2)
                }})
              </ng-container>
            </ng-container>
            <button
              *ngIf="itm.Info"
              [disabled]="!itm.Info"
              [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
              class="btn btn-sm btn-link"
              (click)="onHelperClick(itm.Title, itm.Info)"
            >
              <cds-icon shape="info-standard"></cds-icon>
            </button>
          </label>
          <select
            class="clr-col-12 clr-col-md-9"
            clrSelect
            style="width: 100%"
            [style]="getStyle(itm)"
            [formControlName]="itm._id!"
            (ngModelChange)="inputChanged()"
            [disabled]="itm.Fixed === 3"
          >
            <option
              style="width: 100%"
              *ngFor="let ctlOption of itm.Selection"
              [ngValue]="ctlOption"
            >
              {{ ctlOption }}
            </option>
          </select>
        </clr-select-container>
      </ng-container>
      <ng-container *ngIf="itm.ItemType === 'String'">
        <clr-input-container>
          <label
            class="clr-col-12 clr-col-md-3"
            for="inputContainer"
            [style]="getStyle(itm)"
          >
            {{ itm.Title }}
            <button
              *ngIf="itm.Info"
              [disabled]="!itm.Info"
              [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
              class="btn btn-sm btn-link"
              (click)="onHelperClick(itm.Title, itm.Info)"
            >
              <cds-icon shape="info-standard"></cds-icon>
            </button>
          </label>
          <label
            class="clr-col-12 clr-col-md-9"
            for="inputContainer"
            [style]="getStyle(itm)"
            *ngIf="
              itm.Fixed === 3 &&
              evaluationCalculations$ &&
              evaluationCalculations$.containsCalculations(itm)
            "
            style="width: 100%"
          >
            {{
              evaluationCalculations$.getCalculationFor(itm)?.value?.toFixed(2)
            }}
          </label>
          <input
            *ngIf="itm.Fixed !== 3"
            style="width: 100%"
            [style]="getStyle(itm)"
            class="clr-col-12 clr-col-md-9"
            clrInput
            [formControlName]="itm._id!"
            (ngModelChange)="inputChanged()"
            [disabled]="itm.Fixed === 3"
          />
        </clr-input-container>
      </ng-container>
      <ng-container *ngIf="itm.ItemType === 'Number'">
        <clr-input-container>
          <label
            class="clr-col-12 clr-col-md-3"
            for="inputContainer"
            [style]="getStyle(itm)"
          >
            {{ itm.Title }}
            <button
              *ngIf="itm.Info"
              [disabled]="!itm.Info"
              [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
              class="btn btn-sm btn-link"
              (click)="onHelperClick(itm.Title, itm.Info)"
            >
              <cds-icon shape="info-standard"></cds-icon>
            </button>
          </label>
          <label
            class="clr-col-12 clr-col-md-9"
            for="inputContainer"
            [style]="getStyle(itm)"
            *ngIf="
              itm.Fixed === 3 &&
              evaluationCalculations$ &&
              evaluationCalculations$.containsCalculations(itm)
            "
            style="width: 100%"
          >
            {{
              evaluationCalculations$.getCalculationFor(itm)?.value?.toFixed(2)
            }}
          </label>
          <input
            *ngIf="itm.Fixed !== 3"
            style="width: 100%"
            type="number"
            [style]="getStyle(itm)"
            class="clr-col-12 clr-col-md-9"
            clrInput
            [formControlName]="itm._id!"
            (ngModelChange)="inputChanged()"
          />
        </clr-input-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <br />
  <!-- <div class="appitem"></div> -->
  <br />
  <!-- <p></p>
    <button class="btn btn-outline" (click)="onAddFieldClick()">
      <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
    </button> -->
  <span style="float: left; display: inline-flex">
    <!-- style="float: left; display: flex; flex-direction: column" -->
    <button
      type="submit"
      class="btn btn-outline"
      [clrLoading]="submitTabState"
      [disabled]="!inputsOk || disabled"
      style="display: inline-flex"
    >
      Werte übernehmen
    </button>
    <p
      style="color: red; display: inline-flex; margin-left: 10px"
      [style.visibility]="getVisibilityWarnings()"
    >
      (Bitte Eingaben prüfen)
    </p>
    <br />
  </span>
  <br />
  <!-- </ng-container> -->
</form>

<!-- popup-windows -->

<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
