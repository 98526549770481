import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ContractActions } from './contract.actions';
import { Contract } from 'src/app/models/Contract';

export const contractFeatureKey = 'contract';

export const adapter = createEntityAdapter<Contract>({
  selectId: (contract) => contract._id,
});

export interface State extends EntityState<Contract> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(ContractActions.loadContracts, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(ContractActions.loadContractsSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.contracts, state),
      loading: false,
      error: null,
    };
  }),

  on(ContractActions.loadContractsFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  on(ContractActions.loadContract, (state): State => {
    return {
      ...state,
      // loading: true,
      error: null,
    };
  }),

  on(ContractActions.loadContractSuccess, (state, action): State => {
    return {
      ...adapter.upsertOne(action.contract, state),
      // loading: false,
      error: null,
    };
  }),

  on(ContractActions.loadContractFailure, (state, action): State => {
    return {
      ...state,
      // loading: false,
      error: action.error,
    };
  }),
);
