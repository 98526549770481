import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Evaluation, BaseImage } from 'src/app/models/Evaluation';
import { ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  CheckObj,
  DialogAddImageComponent,
} from '../dialog-add-image/dialog-add-image.component';
import { event } from '@cds/core/internal';

@Component({
  selector: 'app-risks-tab-images-evaluation',
  templateUrl: './risks-tab-images-evaluation.component.html',
  styleUrls: ['./risks-tab-images-evaluation.component.css'],
})
export class RiskTabEvaluationImagesComponent implements OnInit {
  @Input() evaluation: Evaluation | null = null;
  @Input() disabled = false;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  online = this.service.isOnline$;
  filesDialog: DialogAddImageComponent | null = null;

  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private service: AssessmentStoreService) {}

  ngOnInit() {
    this.init.emit(this);
  }

  setDialog($event: any) {
    this.filesDialog = $event as DialogAddImageComponent;
    console.log('Dialog set:', this.filesDialog);
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    if (this.disabled) return false;
    this.dialogAddFile = true;
    return false;
  }

  getThumbImage(image: BaseImage) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: BaseImage) {
    if (this.evaluation) {
      this.service
        .loadEvaluationImage(this.evaluation._id, image._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${image.MIME};base64,${result?.data}`;
          this.dialogViewImg = true;
        });
    }
  }

  onUpdateFile(fileData: any) {
    console.log('onUpdateFile()', fileData);

    const fileDat = fileData as CheckObj;
    if (fileDat.file && this.evaluation) {
      this.service
        .postEvaluationImage(fileDat, this.evaluation)
        .subscribe((result) => {
          this.evaluation = result as Evaluation;
          this.updated.emit(this.evaluation);
          this.ngOnInit();
          this.filesDialog?.fileSend(true, fileDat.idx);
        });
    }
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.evaluation) {
      console.log(`onDeleteFile control?._id : ${control?._id}`);
      this.service
        .deleteEvaluationImage(this.evaluation._id, control?._id)
        .subscribe((C) => {
          this.evaluation = C as Evaluation;
          this.updated.emit(C as Evaluation);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }
}
