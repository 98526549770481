import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { User } from 'src/app/models/User';

export class IsBlockedFilter implements ClrDatagridFilterInterface<User> {
  public selected: string[] = [];

  public changes = new Subject<boolean>();

  public isActive(): boolean {
    return this.selected.length > 0;
  }

  public accepts(user: User): boolean {
    return (
      this.selected.indexOf(
        user.SignIn.IsBlocked.toString().toLowerCase() || 'true',
      ) > -1
    );
  }
}

@Component({
  selector: 'app-users-list-grid',
  templateUrl: './users-list-grid.component.html',
  styleUrls: ['./users-list-grid.component.css'],
})
export class UsersListGridComponent {
  isBlockedFilter: IsBlockedFilter = new IsBlockedFilter();

  selected = {} as User;

  @Input() users$ = new Observable<User[]>();
  @Input() loading$ = new Observable<boolean>();
  @Input() error$ = new Observable<HttpErrorResponse | null>();

  @Input() hideUid = false;
  @Input() hideStatus = false;
  @Input() hideFirstname = false;
  @Input() hideLastname = false;

  invite = false;
  detail = false;
  delete = false;

  constructor(private store: Store) {}

  onInvite() {
    this.invite = true;
  }

  onInvited(value: boolean) {
    this.invite = false;
  }

  onDelete() {
    this.delete = true;
  }

  onDeleted(value: boolean) {
    this.delete = false;
  }

  onEdit() {
    this.detail = true;
  }

  onEdited(value: boolean) {
    this.detail = false;
  }

  toggleBlocked(event: any) {
    if (event.target.checked) {
      this.isBlockedFilter.selected.push(event.target.value);
    } else {
      const colorName = event.target.value;
      const index = this.isBlockedFilter.selected.indexOf(colorName);
      if (index > -1) {
        this.isBlockedFilter.selected.splice(index, 1);
      }
    }

    this.isBlockedFilter.changes.next(true);
  }
}
