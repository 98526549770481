import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Contract } from 'src/app/models/Contract';

import * as CATEGORIES from 'src/app/constants/categories';

@Component({
  selector: 'app-contracts-detail-item',
  templateUrl: './contracts-detail-item.component.html',
  styleUrls: ['./contracts-detail-item.component.css'],
})
export class ContractsDetailItemComponent implements OnInit, OnChanges {
  @Input() contract = {} as Contract;
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  auftrag = false;
  AUFTRAG = CATEGORIES.AUFTRAG;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  ngOnInit(): void {
    this.updateItems();
  }

  updateItems() {
    this.auftrag =
      this.contract?.Documents?.filter((d) =>
        d.Categories.some((c) => c.Uid === CATEGORIES.AUFTRAG),
      ).length > 0;
  }
}
