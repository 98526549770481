import { Component, ViewChild, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Telecontrol } from 'src/app/models/Telecontrol';
import { FormBuilder, FormControl } from '@angular/forms';
import { ArchiveRequest } from 'src/app/shared/Archive-Measure-Request';
import {
  MeasureStoreService,
  ArchivedMeasurement,
} from 'src/app/services/measurearchive-store';
import {
  selectTelemetries,
  selectTelemetriesError,
  selectTelemetriesLoading,
} from '../../telemetries/+store/telemetry.selectors';
import { TelemetryActions } from '../../telemetries/+store/telemetry.actions';
import { ArchiveActions } from './../+store/archives.actions';
import { MeasureArchiveTableComponent } from 'src/app/helper/measure-archive-table/measure-archive-table.component';
import { MeasureArchiveChartComponent } from 'src/app/helper/measure-archive-chart/measure-archive-chart.component';

interface SelectionProperty {
  Name: string;
  Alias: string;
  Unit: string;
}

interface CurrentConfigClientId {
  telemetry: Telecontrol;
  systemProps: Map<string, SelectionProperty>; // [this.defaultSelProp];
  localsProps: Map<string, SelectionProperty>; // [this.defaultSelProp];
  deviceProps: Map<string, SelectionProperty>; // [this.defaultSelProp];
  signalProps: Map<string, SelectionProperty>; // [this.defaultSelProp];
}

@Component({
  selector: 'app-archives-test',
  templateUrl: './archives-test.component.html',
  styleUrls: ['./archives-test.component.css'],
  template: '<app-measure-archive-table></app-measure-archive-table>',
})
export class ArchivesTestComponent implements OnInit {
  // ControlValueAccessor
  @Input() ngModelSignals: SelectionProperty[] = [] as SelectionProperty[];
  // @Input() ngModelInterval: SelectionProperty = {} as SelectionProperty;
  @Input() ngModelInterval = { Enabled: false } as {
    Value: number;
    Unit: string;
    Enabled: boolean;
  };
  @Input() telemetry = {
    MQTT: {
      Enabled: true,
      Identifier: '0',
      Username: 'name',
      Password: 'pw',
    },
  } as Telecontrol; // telemetry = {} as Telemetry;
  @ViewChild(MeasureArchiveTableComponent)
  private childTable!: MeasureArchiveTableComponent;
  @ViewChild(MeasureArchiveChartComponent)
  private childChart!: MeasureArchiveChartComponent;
  telemetriesList$ = this.store.select(selectTelemetries);
  loadingTelemetries$ = this.store.select(selectTelemetriesLoading);
  errorTelemetries$ = this.store.select(selectTelemetriesError);
  fromDate: any;
  readonly lblTxtDefault = ' -keine Auswahl- ';
  lblTxtClientId = this.lblTxtDefault;
  lblTxtSystem = this.lblTxtDefault;
  lblTxtLocation = this.lblTxtDefault;
  lblTxtDevice = this.lblTxtDefault;
  defaultSelProp = {
    Name: '',
    Alias: this.lblTxtDefault,
    Unit: '',
  } as SelectionProperty;
  telemetryMap = new Map<string, CurrentConfigClientId>();
  currentConfig = {} as CurrentConfigClientId;
  clientIds$ = [''];
  systems$ = ['']; //Array<string>();
  locations$ = ['']; //Array<string>();
  devices$ = ['']; //Array<string>();
  @Input() units$ = [
    { unit: 's', name: 'Sekunden' },
    { unit: 'm', name: 'Minuten' },
    { unit: 'h', name: 'Stunden' },
    { unit: 'd', name: 'Tage' },
    { unit: 'w', name: 'Wochen' },
    { unit: 'y', name: 'Jahre' },
  ] as { unit: string; name: string }[];
  @Input() signals$ = [] as SelectionProperty[]; //Array<string>();
  // @Input() dateTimeStartString = '2024-02-21T06:30:00.781' as string;
  // @Input() dateTimeStopString = '2024-02-21T06:40:00.781' as string;
  @Input() dateTimeStartString = '' as string;
  @Input() dateTimeStopString = '' as string;
  currentSignals = [] as any[];
  currentValues = [{}];
  archiveRequest = {
    // Start: new Date('2024-02-21T06:30:00.781Z'), // for time-range
    // Stop: new Date('2024-02-21T06:40:00.781Z'), // for time-range
    Start: new Date(), // for time-range
    Stop: new Date(), // for time-range
    MQTT_Id: 'MQTT_CLIENT_ID_401701535',
    Device: '',
    Channel: '',
    Location: '',
    System: '',
    Signals: [],
    Interval: {
      Value: 1,
      Unit: 'm',
    },
  } as ArchiveRequest;

  public initOpts = {
    locale: 'DE',
  };

  private formBuilder = inject(FormBuilder);

  formFilter = this.formBuilder.group({
    sourceArray: this.formBuilder.array([]),
    sourceData: '',
    intervalCbx: '',
    intervalUnit: new FormControl({
      value: 'Nancy',
      disabled: !this.ngModelInterval.Enabled,
    }),
    intervalValue: new FormControl({
      value: 'Nancy',
      disabled: !this.ngModelInterval.Enabled,
    }),
    // intervalUnit: '',
    // intervalValue: '',
    dateFormFrom: '',
    // dateFormFrom: new FormControl({
    //   value: formatDate(this.archiveRequest.Start, 'yyyy-MM-ddTHH:mm', 'en')
    // }),
  });

  get filter() {
    return this.formFilter.controls;
  }

  dateStartChanged(eventDate: any) {
    const date = eventDate?.srcElement?.value
      ? new Date(eventDate.srcElement.value)
      : new Date();
    this.archiveRequest.Start = date;
  }

  dateStopChanged(eventDate: any) {
    const date = eventDate?.srcElement?.value
      ? new Date(eventDate.srcElement.value)
      : new Date();
    this.archiveRequest.Stop = date;
  }

  intervalClicked() {
    console.log('Interval clicked : ', this.ngModelInterval);
    if (!this.ngModelInterval.Enabled) {
      this.formFilter.get('intervalUnit')?.enable();
      this.formFilter.get('intervalValue')?.enable();
    } else {
      this.formFilter.get('intervalUnit')?.disable();
      this.formFilter.get('intervalValue')?.disable();
    }
  }

  refresh() {
    this.measureStoreService.get(this.telemetry.Uid, this.archiveRequest);
  }

  myObserver = {
    next: (data: ArchivedMeasurement[]) => this.onNext(data),
    error: (error: any) => this.onError(error),
    complete: () => this.onComplete(),
  };

  onNext(telemetryMeasurement: ArchivedMeasurement[]) {
    console.log(telemetryMeasurement);
  }

  onError(error: any) {
    // console.log(error);
  }

  onComplete() {
    //
  }

  constructor(
    private measureStoreService: MeasureStoreService,
    private store: Store, // private child: MeasureArchiveTableComponent,
  ) {
    this.store.dispatch(TelemetryActions.loadTelemetries());
    this.store.dispatch(ArchiveActions.loadArchivedMeasurements());
    // this.formFilter.get('sourceData')?.valueChanges.subscribe((value) => {});
  }

  resetRequest() {
    this.archiveRequest = {
      // Start: new Date('2024-02-21T06:30:00.781Z'), // for time-range
      // Stop: new Date('2024-02-21T06:40:00.781Z'), // for time-range
      Start: new Date(), // for time-range
      Stop: new Date(), // for time-range
      MQTT_Id: '',
      Device: '',
      Channel: '',
      Location: '',
      System: '',
      Signals: [],
      Interval: {
        Value: 1,
        Unit: 'm',
      },
    } as ArchiveRequest;
    this.lblTxtClientId = this.lblTxtDefault;
    this.lblTxtSystem = this.lblTxtDefault;
    this.lblTxtLocation = this.lblTxtDefault;
    this.lblTxtDevice = this.lblTxtDefault;
  }

  onClientIdClick(item: any) {
    // this.resetRequest();
    this.archiveRequest.MQTT_Id = item;
    this.lblTxtClientId = item;
    this.currentConfig = this.telemetryMap.get(item) as CurrentConfigClientId;

    this.telemetry = this.currentConfig.telemetry as Telecontrol;
    this.systems$ = Array.from(this.currentConfig.systemProps.keys());
    this.locations$ = Array.from(this.currentConfig.localsProps.keys());
    this.devices$ = Array.from(this.currentConfig.deviceProps.keys());
    this.signals$ = Array.from(this.currentConfig.signalProps.values());
  }

  onSystemClick(item: any) {
    this.archiveRequest.System = this.currentConfig.systemProps?.get(item)
      ?.Name as string;
    this.lblTxtSystem = item;
  }
  clearSystemClick() {
    this.archiveRequest.System = '';
    this.lblTxtSystem = this.lblTxtDefault;
  }

  onLocationClick(item: any) {
    this.archiveRequest.Location = this.currentConfig.localsProps?.get(item)
      ?.Name as string;
    this.lblTxtLocation = item;
  }
  clearLocationClick() {
    this.archiveRequest.Location = '';
    this.lblTxtLocation = this.lblTxtDefault;
  }

  onDeviceClick(item: any) {
    this.archiveRequest.Device = this.currentConfig.deviceProps?.get(item)
      ?.Name as string;
    this.lblTxtDevice = item;
  }
  clearDeviceClick() {
    this.archiveRequest.Device = '';
    this.lblTxtDevice = this.lblTxtDefault;
  }

  filterClicked() {
    this.archiveRequest.Signals = [];
    if (this.ngModelSignals != null && this.ngModelSignals?.length > 0) {
      this.archiveRequest.Signals = this.ngModelSignals?.map((x) => x?.Name);
    }
    if (this.ngModelInterval != null && this.ngModelInterval.Enabled) {
      this.archiveRequest.Interval.Value = this.ngModelInterval.Value;
      this.archiveRequest.Interval.Unit = this.ngModelInterval.Unit;
    } else {
      console.log('Interval Disabled : ', this.ngModelInterval.Enabled);
      this.archiveRequest.Interval = {} as {
        Value: number;
        Unit: string;
      };
    }
    this.measureStoreService.get(this.telemetry.Uid, this.archiveRequest);
    try {
      this.childTable.refreshArchiveTable();
      this.childChart.refreshArchiveChart();
    } catch (err) {
      console.log(err);
    }
  }

  clearCliecked() {
    this.resetRequest();
    this.ngModelSignals = [];
  }

  ngOnInit(): void {
    this.measureStoreService.get(this.telemetry.Uid, this.archiveRequest);

    this.telemetriesList$.forEach((telemetries) => {
      telemetries.forEach((telemetry) => {
        // if (!telemetry.MQTT?.Enabled) {
        //   console.log(
        //     `MQTT-ID ${telemetry.MQTT?.Id} is NOT enabled... => return;`,
        //   );
        //   return;
        // }

        const systemProps = new Map();
        const localsProps = new Map();
        const deviceProps = new Map();
        const signalProps = new Map();

        let tcount = 0;
        // telemetry.Measurements?.forEach((measurement) => {
        //   if (!this.systems$.includes(measurement?.System?.Name)) {
        //     const sysProp = {
        //       Name: measurement.System?.Name,
        //       Alias: measurement.System?.Alias,
        //     } as SelectionProperty;
        //     if (tcount > 0) {
        //       systemProps.set('Süstehm', {
        //         Name: 'Sys',
        //         Alias: 'Ali',
        //       } as SelectionProperty);
        //     } else if (measurement?.System?.Alias.length == 0) {
        //       // this.systems$.push(measurement.System?.Name);
        //       systemProps.set(measurement.System?.Name, sysProp);
        //     } else {
        //       // this.systems$.push(measurement.System?.Alias);
        //       systemProps.set(measurement.System?.Alias, sysProp);
        //     }
        //   }
        //   if (!this.locations$.includes(measurement.Location?.Name)) {
        //     const localeProp = {
        //       Name: measurement.Location?.Name,
        //       Alias: measurement.Location?.Alias,
        //     } as SelectionProperty;
        //     if (measurement.Location?.Alias.length == 0) {
        //       localsProps.set(measurement.Location?.Name, localeProp);
        //       // this.locations$.push(measurement.Location?.Name);
        //     } else {
        //       localsProps.set(measurement.Location?.Alias, localeProp);
        //       // this.locations$.push(measurement.Location?.Alias);
        //     }
        //   }
        //   if (!this.devices$.includes(measurement.Device?.Name)) {
        //     const deviceProp = {
        //       Name: measurement.Device?.Name,
        //       Alias: measurement.Device?.Alias,
        //     } as SelectionProperty;
        //     if (measurement.Device?.Alias.length == 0) {
        //       deviceProps.set(measurement.Device?.Alias, deviceProp);
        //       // this.devices$.push(measurement.Device?.Name);
        //     } else {
        //       deviceProps.set(measurement.Device?.Alias, deviceProp);
        //       // this.devices$.push(measurement.Device?.Name);
        //     }
        //   }
        //   measurement.Channels.forEach((channel) => {
        //     channel.Signals.forEach((signal) => {
        //       const signalProp = {
        //         Name: signal.Name,
        //         Alias: signal.Alias,
        //       };
        //       // if (!this.signals$.includes(signal?.Name)) {
        //       if (signal?.Alias.length == 0) {
        //         // this.signals$.push(signal?.Name);
        //         signalProps.set(signal.Name, signalProp);
        //       } else {
        //         // this.signals$.push(signal.Alias);
        //         signalProps.set(signal.Alias, signalProp);
        //       }
        //     });
        //     // });
        //   });
        // });
        // if (!this.clientIds$.includes(telemetry.MQTT?.Id)) {
        //   this.clientIds$.push(telemetry.MQTT?.Id);
        //   this.telemetryMap.set(telemetry.MQTT?.Id, {
        //     telemetry,
        //     systemProps,
        //     localsProps,
        //     deviceProps,
        //     signalProps,
        //   } as CurrentConfigClientId);
        //   this.telemetry = telemetry;
        //   this.archiveRequest.MQTT_Id = telemetry.MQTT?.Id;
        //   this.lblTxtClientId = telemetry.MQTT?.Id;
        //   this.systems$ = Array.from(systemProps.keys());
        //   this.locations$ = Array.from(localsProps.keys());
        //   this.devices$ = Array.from(deviceProps.keys());
        //   // this.signals$ = Array.from(signalProps.keys());
        //   this.signals$ = Array.from(signalProps.values());

        //   this.currentConfig = this.telemetryMap.get(
        //     telemetry.MQTT?.Id,
        //   ) as CurrentConfigClientId;
        // }
        tcount++;
      });
    });
    // this.clientIds$ = Array.from(this.telemetryMap.keys());
  }
}
