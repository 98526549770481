import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { StatusService } from '../services/status.service';
import { AuthService } from '../services/auth.service';
import { SocketClientService } from '../services/socket-client.service';
import { MqttEventService } from '../services/mqtt-event.service';
import { TelecontrolMessagesService } from '../shared/telecontrol-messages.service';
import { TelemetryPresetsService } from '../shared/telemetry-presets.service';

import {
  selectProfil,
  selectProfilError,
  selectProfilLoading,
  selectProfilRoles,
} from '../views/users/+profil/profil.selectors';
import { ProfilActions } from '../views/users/+profil/profil.actions';

import {
  selectTelecontrols,
  selectTelecontrolsError,
  selectTelecontrolsLoading,
} from '../views/telecontrols/+store/telecontrol.selectors';
import { TelecontrolActions } from '../views/telecontrols/+store/telecontrol.actions';

import { LayoutService } from '../shared/layout.service';
import { AssessmentStoreService } from '../services/assessment.service';

//import { AssessmentStoreService } from '../services/assessment.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  clrVerticalNavCollapsed = false;

  info = 0;
  warn = 0;
  alarm = 0;
  update = false;

  // FIXME: loading$ und error$ - werden diese angezeigt?
  profil$ = this.store.select(selectProfil);
  loading$ = this.store.select(selectProfilLoading);
  error$ = this.store.select(selectProfilError);

  // FIXME: loading$ und error$ - werden diese angezeigt?
  telecontrols$ = this.store.select(selectTelecontrols);
  loadingTelecontrols$ = this.store.select(selectTelecontrolsLoading);
  errorTelecontrols$ = this.store.select(selectTelecontrolsError);

  isManager$ = this.store.select(selectProfilRoles(['manager']));
  isService$ = this.store.select(selectProfilRoles(['service']));
  risksOnline$ = this.risksService.isOnline$;
  risksInSync$ = this.risksService.isInSync$;

  constructor(
    private mqttEventService: MqttEventService,
    private telemetryMessagesService: TelecontrolMessagesService,
    private telemetryPresetsService: TelemetryPresetsService,
    private store: Store,
    private authService: AuthService,
    private socketService: SocketClientService,
    private layoutService: LayoutService,
    private statusService: StatusService,
    private risksService: AssessmentStoreService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.socketService.connectClientSession(profil.Uid);
      }
    });

    // this.profil$.subscribe((profil) => {
    //   if (profil?.Features?.Risks && this.risksService.isOnline$.value) {
    //     if (this.risksService.offlineUpdates()) {
    //       this.risksService.checkForOfflineData();
    //     }
    //   }
    // });

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.store.dispatch(TelecontrolActions.loadTelecontrols());

        this.telecontrols$.subscribe((telecontrols) => {
          telecontrols.forEach((telecontrol) => {
            if (telecontrol.MQTT?.Identifier) {
              this.mqttEventService.create(telecontrol);
            }
          });

          telecontrols.forEach((telecontrol) => {
            if (telecontrol.MQTT?.Identifier) {
              telecontrol.Presets?.forEach((preset) => {
                this.telemetryPresetsService.update(
                  telecontrol.MQTT.Identifier,
                  preset,
                );
              });
            }
          });

          this.telemetryMessagesService.info.subscribe((value) => {
            this.info = value;
          });

          this.telemetryMessagesService.warn.subscribe((value) => {
            this.warn = value;
          });

          this.telemetryMessagesService.alarm.subscribe((value) => {
            this.alarm = value;
          });
        });
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil?.Features?.Risks && this.risksService.isOnline$.value) {
        if (this.risksService.offlineUpdates()) {
          this.risksService.startSynchronisation();
        }
      }
    });

    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  onLogout() {
    this.authService.logout();
    this.socketService.closeClientSession();
  }

  onUpdate() {
    document.location.reload();
  }

  checkRiskSync() {
    this.risksService.checkForOfflineUpdates();
    if (this.risksService.offlineUpdates())
      this.risksService.startSynchronisation();
  }

  readThemeMode() {
    return this.layoutService.readThemeMode();
  }

  public onToggleTheme() {
    this.layoutService.toggleMode();
  }
}
