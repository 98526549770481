import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Store } from '@ngrx/store';
import { FactoryActions } from '../views/factories/+store/factory.actions';
import { StationActions } from '../views/stations/+store/station.actions';
import { ContractActions } from '../views/contracts/+store/contract.actions';
import { DocumentActions } from '../views/documents/+store/document.actions';
import { BehaviorSubject } from 'rxjs';
import { Factory } from '../models/Factory';
import { Station } from '../models/Station';
import { Contract } from '../models/Contract';
import { Telemetry } from '../models/Telemetry';
import { Document } from 'src/app/models/Document';
import { TelemetryActions } from '../views/telemetries/+store/telemetry.actions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {
  socket: any = null;

  private FactorySubject = new BehaviorSubject<Factory>({} as Factory);
  private StationSubject = new BehaviorSubject<Station>({} as Station);
  private ContractSubject = new BehaviorSubject<Contract>({} as Contract);
  private DocumentSubject = new BehaviorSubject<Document>({} as Document);
  private TelemetrySubject = new BehaviorSubject<Telemetry>({} as Telemetry);

  public factoryObservable = this.FactorySubject.asObservable();
  public stationObservable = this.StationSubject.asObservable();
  public contractObservable = this.ContractSubject.asObservable();
  public documentObservable = this.DocumentSubject.asObservable();
  public telemetryObservable = this.TelemetrySubject.asObservable();

  constructor(private store: Store) {}

  public connectClientSession(uid: string) {
    // Wenn die aktuelle Socket-Verbindung aktiv ist und der `uid` gleich ist, mache nichts
    if (this.socket && this.socket.connected && this.socket.uid === uid) {
      return;
    }

    // Falls die Verbindung aktiv ist, aber der `uid` sich ändert, schließe die alte Verbindung
    if (this.socket && this.socket.uid !== uid) {
      this.closeClientSession();
    }

    // Neue Socket-Verbindung aufbauen
    this.socket = io(`${environment.importUrl}/user`, {
      path: '/wss/store-import',
    });

    this.socket.uid = uid;

    this.socket.on('connect', () => {
      this.socket.emit('uid', uid);
      console.log(`Socket: Uid [${uid}] - Status: [connect]`);
    });

    this.socket.on('disconnect', () => {
      console.log(`Socket: Uid [${uid}] - Status: [disconnect]`);
    });

    this.socket.on('connect_error', (err: any) => {
      console.log(err);
    });

    this.socket.on('connect_timeout', () => {
      console.error('Connection timeout');
    });

    this.socket.on('reconnect_attempt', () => {
      console.log('Reconnecting...');
    });

    this.socket.on('reconnect_failed', () => {
      console.error('Reconnection failed');
    });

    this.socket.on('factory', (factory: Factory) => {
      this.FactorySubject.next(factory);
      this.store.dispatch(FactoryActions.loadFactory({ uid: factory.Uid }));

      console.log(
        `Socket: Factory [${factory.Uid}] - Time [${new Date().toISOString()}]`,
      );
    });

    this.socket.on('contract', (contract: Contract) => {
      this.ContractSubject.next(contract);
      this.store.dispatch(ContractActions.loadContract({ uid: contract.Uid }));

      console.log(
        `Socket: Contract [${
          contract.Uid
        }] - Time [${new Date().toISOString()}]`,
      );
    });

    this.socket.on('station', (station: Station) => {
      this.StationSubject.next(station);
      this.store.dispatch(StationActions.loadStation({ uid: station.Uid }));
      console.log(
        `Socket: Station [${station.Uid}] - Time [${new Date().toISOString()}]`,
      );
    });

    this.socket.on('telemetry', (telemetry: Telemetry) => {
      this.TelemetrySubject.next(telemetry);
      this.store.dispatch(
        TelemetryActions.loadTelemetry({ uid: telemetry.Uid }),
      );

      console.log(
        `Socket: Telemetry [${
          telemetry.Uid
        }] - Time [${new Date().toISOString()}]`,
      );
    });

    this.socket.on('document', (document: Document) => {
      this.DocumentSubject.next(document);
      this.store.dispatch(DocumentActions.loadSocket());

      console.log(
        `Socket: Document [${
          document.Uid
        }] - Time [${new Date().toISOString()}]`,
      );
    });
  }

  public closeClientSession = () => {
    if (this.socket != null) {
      this.socket.disconnect();
      this.socket.close();
    }
  };
}
