import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FactoryActions } from './factory.actions';
import { FactoryStoreService } from 'src/app/services/factory-store.service';

@Injectable()
export class FactoryEffects {
  private service = inject(FactoryStoreService);

  loadFactories$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(FactoryActions.loadFactories),
      mergeMap(() =>
        this.service.getAll().pipe(
          map((factories) =>
            FactoryActions.loadFactoriesSuccess({ factories: factories }),
          ),
          catchError((error) =>
            of(FactoryActions.loadFactoriesFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadFactory$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(FactoryActions.loadFactory),
      mergeMap((action) =>
        this.service.getSingle(action.uid).pipe(
          map((factory) =>
            FactoryActions.loadFactorySuccess({ factory: factory }),
          ),
          catchError((error) =>
            of(FactoryActions.loadFactoryFailure({ error })),
          ),
        ),
      ),
    );
  });
}
