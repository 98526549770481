<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> /
        <a routerLink="/risks">Gefährdungsbeurteilung</a> / Anlage
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="card">
  <!-- <h3 class="card-header">Header</h3> -->
  <div class="card-block">
    <h2 class="card-title">Anlage</h2>
    <!-- <app-risks-cabinet-table [cabinet$]="cabinet$" /> -->
    <app-risks-cabinet-table [cabinet]="cabinet" />
    <h3 class="card-titel">Bewertungen</h3>
    <div class="card-text">
      <app-risks-evaluations-overview
        #auswertungenTab
        [cabinet]="cabinet!"
      ></app-risks-evaluations-overview>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
