import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedContracts } from '../+store/contract.selectors';
import { ContractActions } from '../+store/contract.actions';

@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.css'],
})
export class ContractsListComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedContracts$ = this.store.select(selectCombinedContracts);

  constructor(private store: Store) {}

  ngOnInit(): void {
    // this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(ContractActions.loadContracts());
      }
    });
  }
}
