<div class="card">
  <h3 class="card-header">
    <cds-icon shape="contract" size="md"></cds-icon>
    {{ contract.Nr }} | {{ contract.Name }}
    <span style="float: right">
      <cds-icon shape="details" size="md"></cds-icon>
    </span>
  </h3>
  <div class="card-block">
    <h4 class="card-title" *ngIf="error">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>
    <div class="card-text">
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
            </th>
            <td>{{ contract.Nr }}</td>
          </tr>
          <tr>
            <th><cds-icon shape="tag"></cds-icon>&nbsp;Name</th>
            <td>{{ contract.Name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card" *ngIf="contract.Factory">
  <h3 class="card-header">
    <cds-icon shape="factory" size="md"></cds-icon>
    {{ contract.Factory.Nr }} | {{ contract.Factory.Name }}
    <span style="float: right">
      <cds-icon shape="details" size="md"></cds-icon>
    </span>
  </h3>
  <div class="card-block">
    <h4 class="card-title" *ngIf="error">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>
    <div class="card-text">
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
            </th>
            <td>{{ contract.Factory.Nr }}</td>
          </tr>
          <tr>
            <th><cds-icon shape="tag"></cds-icon>&nbsp;Name</th>
            <td>{{ contract.Factory.Name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-container *ngIf="auftrag">
  <div class="clr-row">
    <div class="clr-col-12">
      <app-documents-list-grid-card
        *ngIf="contract.Documents"
        [number]="AUFTRAG"
        [name]="'Auftragsbestätigungen'"
        [documents]="contract.Documents"
      ></app-documents-list-grid-card>
    </div>
  </div>
</ng-container>

<div class="clr-row">
  <div class="clr-col-12">
    <app-stations-list-grid-card
      [extern]="true"
      [stations]="contract.Stations"
      [loading]="loading"
      [error]="error"
    >
    </app-stations-list-grid-card>
  </div>
</div>
