//export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'info',
  ERROR = 'error',
}

export enum MqttProtocol {
  wss = 'wss',
}

export enum MqttProtocolId {
  MQTT = 'MQTT',
}

export enum MqttProtocolVersion {
  THREE = 3,
}

export enum MqttPort {
  // WS_PORT_OPEN = 1083,
  // WS_PORT_CLOSED = 1084,
  // WSS_PORT_OPEN = 8083,
  WSS_PORT_CLOSED = 8084,
}

export interface IEnvironment {
  name: string;
  production: boolean;

  userApi: string;
  importApi: string;
  exportApi: string;
  tsdbApi: string;
  riskApi: string;

  importUrl: string;

  mqttHostname: string;
  mqttProtocol: MqttProtocol;
  mqttPort: MqttPort;
  mqttPath: string;

  // mqttProtocolVersion: MqttProtocolVersion;
  // mqttProtocolId: MqttProtocolId;

  VAPID_PUBLIC_KEY: string;
}
