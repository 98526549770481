<div class="clr-row">
  <div class="clr-col-12">
    <ng-container *ngIf="stromlaufplan">
      <app-documents-list-grid-card
        *ngIf="station.Documents"
        [number]="STROMLAUFPLAN"
        [name]="'Stromlaufpläne'"
        [documents]="station.Documents"
      ></app-documents-list-grid-card>
    </ng-container>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <app-documents-list-grid-card
      *ngIf="station.Documents"
      [number]="THERMOGRAFIE"
      [name]="'Thermografie'"
      [documents]="station.Documents"
    ></app-documents-list-grid-card>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <app-documents-list-grid-card
      *ngIf="station.Documents"
      [number]="PROTOKOLL"
      [name]="'Protokoll'"
      [documents]="station.Documents"
    ></app-documents-list-grid-card>
  </div>
</div>

<div class="card">
  <h3 class="card-header">
    <cds-icon shape="host" size="md"></cds-icon>
    {{ station.Name }}
    <span style="float: right">
      <cds-icon shape="details" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error">
      <div *ngIf="error" class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="tag"></cds-icon>&nbsp;Name
            </th>
            <td>
              {{ station.Name }}
            </td>
          </tr>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="flag"></cds-icon>&nbsp;Standort
            </th>
            <td>
              {{ station.Telemetry?.Name }}
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="calendar"></cds-icon>&nbsp;Inspektion
            </th>
            <td>
              {{ station.Inspection | date: "dd.MM.yyyy" }}
            </td>
          </tr>
          <tr>
            <th><cds-icon shape="calendar"></cds-icon>&nbsp;Wartung</th>
            <td>
              {{ station.Maintenance | date: "dd.MM.yyyy" }}
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">Bezeichnung</th>
            <td>
              {{ station.Nameplate?.Description }}
            </td>
          </tr>
          <tr>
            <th>Hersteller</th>
            <td>
              {{ station.Nameplate?.Manufacturer }}
            </td>
          </tr>
          <tr>
            <th>Type</th>
            <td>
              {{ station.Nameplate?.Type }}
            </td>
          </tr>
          <tr>
            <th>Baujahr</th>
            <td>
              {{ station.Nameplate?.ConstructionYear }}
            </td>
          </tr>
          <tr>
            <th>Serienummer</th>
            <td>
              {{ station.Nameplate?.SerialNo }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="card-footer">footer</div> -->
</div>
