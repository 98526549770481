import { Component, Input } from '@angular/core';
import { Telecontrol } from 'src/app/models/Telecontrol';

@Component({
  selector: 'app-telecontrols-messages-grid-card',
  templateUrl: './telecontrols-messages-grid-card.component.html',
  styleUrls: ['./telecontrols-messages-grid-card.component.css'],
})
export class TelecontrolsMessagesGridCardComponent {
  @Input() telecontrol = {} as Telecontrol;
  @Input() extern = false;
}
