import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { UserActions } from './user.actions';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class UserEffects {
  private service = inject(AuthService);

  // load users
  loadAll$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.loadUsersAll),
      mergeMap(() =>
        this.service.getUsersAll().pipe(
          map((users) => UserActions.loadUsersSuccess({ users: users })),
          catchError((error) => of(UserActions.loadUsersFailure({ error }))),
        ),
      ),
    );
  });

  // // load users
  // loadUser$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(UserActions.loadUser),
  //     mergeMap((action) =>
  //       this.service.getUser(action.username).pipe(
  //         map((user) => UserActions.loadUserSuccess({ user: user })),
  //         catchError((error) => of(UserActions.loadUsersFailure({ error }))),
  //       ),
  //     ),
  //   );
  // });

  loadUsers$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.loadUsers),
      mergeMap(() =>
        this.service.getUsers().pipe(
          map((users) => UserActions.loadUsersSuccess({ users: users })),
          catchError((error) => of(UserActions.loadUsersFailure({ error }))),
        ),
      ),
    );
  });

  // lockUser$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(UserActions.lockUser),
  //     mergeMap((action) =>
  //       this.service.lockUser(action.id, action.status).pipe(
  //         map((user) => UserActions.loadUserSuccess({ user: user })),
  //         catchError((error) => of(UserActions.loadUsersFailure({ error }))),
  //       ),
  //     ),
  //   );
  // });

  patchUserFactoryUserStore$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.patchPushFactoryUserStore),
      mergeMap((action) =>
        this.service
          .patchPushFactoryUserStore(action.user, action.factory)
          .pipe(
            map((user) => UserActions.loadUserSuccess({ user: user })),
            catchError((error) => of(UserActions.loadUsersFailure({ error }))),
          ),
      ),
    );
  });

  patchPullFactoryUserStore$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.patchPullFactoryUserStore),
      mergeMap((action) =>
        this.service
          .patchPullFactoryUserStore(action.user, action.factory)
          .pipe(
            map((user) => UserActions.loadUserSuccess({ user: user })),
            catchError((error) => of(UserActions.loadUsersFailure({ error }))),
          ),
      ),
    );
  });

  // delete user
  deleteUser$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.deleteUser),
      mergeMap((action) =>
        this.service.deleteUser(action.id).pipe(
          map(() => UserActions.deleteUserSuccess({ id: action.id })),
          catchError((error) => of(UserActions.loadUsersFailure({ error }))),
        ),
      ),
    );
  });

  // invite user
  inviteUser$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.inviteUser),
      mergeMap((action) =>
        this.service
          .invite(
            action.uid,
            action.xid,
            action.username,
            action.firstname,
            action.lastname,
            action.roles,
          )
          .pipe(
            map((result) =>
              UserActions.inviteUserSuccess({ user: result.User }),
            ),
            catchError((error) => of(UserActions.loadUsersFailure({ error }))),
          ),
      ),
    );
  });

  // update roles
  updateUser$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActions.updateUser),
      mergeMap((action) =>
        this.service
          .updateUser(
            action.id,
            action.blocked,
            action.firstname,
            action.lastname,
            action.roles,
          )
          .pipe(
            map((result) => UserActions.updateUserSuccess({ user: result })),
            catchError((error) => of(UserActions.loadUsersFailure({ error }))),
          ),
      ),
    );
  });
}
