import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  merge,
  Observable,
  reduce,
  Subject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Cabinet,
  CabinetImg,
  compareCabinets,
  ImageEntry,
  LowCabinet,
} from '../models/Cabinet';
import { LowTemplate, Template } from '../models/Template';
import {
  BaseImage,
  Evaluation,
  compareEvaluations,
} from '../models/Evaluation';
import { Key, NgxIndexedDBService } from 'ngx-indexed-db';
import { CheckObj } from '../views/risks/dialog-add-image/dialog-add-image.component';

interface extendFile extends File {
  originalname: string;
  encoding: string;
  mimetype: string;
  buffer: Buffer;
}

interface IdxedCabinetImg {
  _id: string;
  cabinetId: string;
  imgFile: File;
}
interface IdxedEvaluationImg {
  _id: string;
  evaluationId: string;
  imgFile: File;
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentStoreService {
  public isOnline$ = new BehaviorSubject<boolean>(window.navigator.onLine);
  public isInSync$ = new BehaviorSubject<boolean>(false);
  private apiUrl = environment.riskApi;
  private updatedCabinetsDone = true;
  private updatedTemplatesDone = true;
  private updatedEvaluationsDone = true;
  private createdCabinetsDone = true;
  private createdCabinetImagesDone = true;
  private createdTemplatesDone = true;
  private createdEvaluationsDone = true;
  private createdEvaluationImagesDone = true;
  private deletedCabinetsDone = true;
  private deletedTemplatesDone = true;
  private deletedEvaluationsDone = true;
  private deletedCabinetImagesDone = true;
  private deletedEvaluationImagesDone = true;

  constructor(
    private offlineDb: NgxIndexedDBService,
    private http: HttpClient,
  ) {
    this.checkForOfflineUpdates();
    this.isSyncFinished();
    this.listenToOnlineStatus();
  }

  checkForOfflineUpdates() {
    this.offlineDb.getAll('Cabinet_created').subscribe((cc) => {
      this.createdCabinetsDone = !(cc && cc?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Cabinet_created length:', cc.length);
    });
    this.offlineDb.getAll('CabinetImage_created').subscribe((cic) => {
      this.createdCabinetImagesDone = !(cic && cic?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('CabinetImage_created length:', cic.length);
    });
    this.offlineDb.getAll('Evaluation_created').subscribe((ec) => {
      this.createdEvaluationsDone = !(ec && ec?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Evaluation_created length:', ec.length);
    });
    this.offlineDb.getAll('EvaluationImage_created').subscribe((eic) => {
      this.createdEvaluationImagesDone = !(eic && eic?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('EvaluationImage_created length:', eic.length);
    });
    this.offlineDb.getAll('Template_created').subscribe((tc) => {
      this.createdTemplatesDone = !(tc && tc?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Template_created length:', tc.length);
    });

    this.offlineDb.getAll('Cabinet_updated').subscribe((cu) => {
      this.updatedCabinetsDone = !(cu && cu?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Cabinet_updated length:', cu.length);
    });
    this.offlineDb.getAll('Evaluation_updated').subscribe((eu) => {
      this.updatedEvaluationsDone = !(eu && eu?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Evaluation_updated length:', eu.length);
    });
    this.offlineDb.getAll('Template_updated').subscribe((tu) => {
      this.updatedTemplatesDone = !(tu && tu?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Template_updated length:', tu.length);
    });

    this.offlineDb.getAll('Cabinet_deleted').subscribe((cd) => {
      this.deletedCabinetsDone = !(cd && cd?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Cabinet_deleted length:', cd.length);
    });
    this.offlineDb.getAll('CabinetImage_deleted').subscribe((cid) => {
      this.deletedCabinetImagesDone = !(cid && cid?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('CabinetImage_deleted length:', cid.length);
    });
    this.offlineDb.getAll('Evaluation_deleted').subscribe((ed) => {
      this.deletedEvaluationsDone = !(ed && ed?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Evaluation_deleted length:', ed.length);
    });
    this.offlineDb.getAll('EvaluationImage_deleted').subscribe((eid) => {
      this.deletedEvaluationImagesDone = !(eid && eid?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('EvaluationImage_deleted length:', eid.length);
    });
    this.offlineDb.getAll('Template_deleted').subscribe((td) => {
      this.deletedTemplatesDone = !(td && td?.length > 0);
      if (!this.createdCabinetsDone)
        console.log('Template_deleted length:', td.length);
    });
  }

  listenToOnlineStatus(): void {
    console.info('listen to "online-status" for risk service');
    window.addEventListener('online', () => {
      console.log('switch to online-state');
      this.isOnline$.next(true);
      this.startSynchronisation();
    });
    window.addEventListener('offline', () => {
      console.log('switch to offline-state');
      this.isOnline$.next(false);
    });
  }

  startSynchronisation(): void {
    this.checkForOfflineUpdates();
    if (this.offlineUpdates() && this.isOnline$.value) {
      this.isInSync$.next(true);
      console.log('SYNC is starting');
      // ---------- new Cabinets ------------------ //
      if (!this.createdCabinetsDone) {
        console.log('SYNC new Cabinets available.');
        this.offlineDb
          .getAll<LowCabinet>('Cabinet_created')
          .subscribe((offlineCreated) => {
            try {
              console.log(`SYNC new Cabints > offline`, offlineCreated?.length);
              const cabinetSet: LowCabinet[] = [];
              offlineCreated.forEach((offCab) => {
                console.log(`SYNC new Cabinets > forEach:`, offCab);
                const newCab = {
                  Uid: offCab.Uid,
                  // Template: offCab.Template,
                  Anlage: offCab.Anlage,
                  Images: offCab.Images,
                } as LowCabinet;
                cabinetSet.push(newCab);
              });
              console.log(
                `SYNC new Cabinets > POST /cabinets/amount :`,
                cabinetSet?.length,
              );

              this.http
                .post<Cabinet[]>(`${this.apiUrl}/cabinets/amount`, cabinetSet)
                .subscribe((freshCabinets) => {
                  console.log(
                    `SYNC:cabinets_created result[${freshCabinets.length}]`,
                  );
                  if (cabinetSet.length == freshCabinets.length) {
                    const idsDel = offlineCreated
                      .filter((c) => c._id !== undefined)
                      .map((c) => c._id) as Key[];
                    const resIds = freshCabinets.map((c) => c._id);
                    console.log(`SYNC:cabinets_created ids:`, resIds);
                    this.offlineDb.bulkPut<Cabinet>('Cabinet', freshCabinets);
                    this.offlineDb.bulkDelete('Cabinet_created', idsDel);
                  } else {
                    console.error(
                      `cabinetSet.length[${cabinetSet.length}] !== cabinets.length[${freshCabinets.length}]`,
                    );
                  }
                  this.createdCabinetsDone = true;
                  this.isSyncFinished();
                });
            } catch (err) {
              console.error('SYNC new Cabinets > ERROR:', err);
            }
          });
      }
      // ---------- updated Cabinets -------------- //
      if (!this.updatedCabinetsDone) {
        console.log('SYNC updated Cabinets available.');
        this.offlineDb
          .getAll<ImageEntry>('Cabinet_updated')
          .subscribe((resultSet) => {
            const cobinetsWithImgs = resultSet.filter(
              (c) =>
                (c as ImageEntry)._id !== undefined &&
                (c as ImageEntry).imgFile !== undefined,
            );
            const idsCabinets = resultSet
              .filter(
                (c) =>
                  (c as ImageEntry)._id !== undefined &&
                  (c as ImageEntry).imgFile === undefined,
              )
              .map((c) => (c as ImageEntry)._id);
            console.log(
              `SYNC:cabinets without images [${idsCabinets.length}]`,
              idsCabinets,
            );
            this.offlineDb
              .bulkGet('Cabinet', idsCabinets)
              .subscribe((resCabinets: unknown) => {
                console.log(`SYNC:cabinets from Offline data:`, resCabinets);
                this.http
                  .patch<Cabinet[]>(
                    `${this.apiUrl}/cabinets/amount/`,
                    resCabinets,
                  )
                  .subscribe((httpCabinets) => {
                    if ((resCabinets as []).length == httpCabinets.length) {
                      console.log(
                        `SYNC:cabinets_updated [${httpCabinets.length}] ids:`,
                      );
                      this.offlineDb.bulkDelete('Cabinet_updated', idsCabinets);
                      this.updatedCabinetsDone = true;
                      this.isSyncFinished();
                    }
                  });
              });
            this.createdCabinetImagesDone =
              cobinetsWithImgs.length > 0 ? false : true;
            let successResponse = 0;
            let failerResponse = 0;
            for (let i = 0; i < cobinetsWithImgs.length; i += 1) {
              const imgCabinet = cobinetsWithImgs[i];
              console.log(
                `SYNC [${cobinetsWithImgs.length}] updated Cabinets with Images available.`,
              );
              this.offlineDb
                .getByID('Cabinet', imgCabinet._id)
                .subscribe((localCabinet) => {
                  const formData = new FormData();
                  formData.append('file', imgCabinet.imgFile);
                  formData.append('fileName', imgCabinet.imgFile.name);
                  formData.append(
                    'cabinet',
                    JSON.stringify(localCabinet as Cabinet),
                  );
                  this.http
                    .post(`${this.apiUrl}/cabinets/images/`, formData, {
                      reportProgress: true,
                      observe: 'events',
                    })
                    .subscribe((event) => {
                      if (event.type == HttpEventType.Response) {
                        if (typeof event.body == typeof HttpErrorResponse) {
                          failerResponse += 1;
                        }
                        if (event.body !== null) {
                          successResponse += 1;
                        }
                        if (
                          i == cobinetsWithImgs.length - 1 &&
                          i == successResponse
                        ) {
                          this.createdCabinetImagesDone = true;
                          this.isSyncFinished();
                        }
                      }
                    });
                });
            }
          });
      }
      // ---------- deleted Cabinets -------------- //
      if (!this.deletedCabinetsDone) {
        console.log('SYNC deleted Cabinets available.');
        this.offlineDb
          .getAll<{ _id: string }>('Cabinet_deleted')
          .subscribe((result) => {
            console.log(`SYNC:Cabinet_deleted result[${result.length}]`);
            result.forEach((idObj) => {
              this.http
                .delete<Cabinet>(`${this.apiUrl}/cabinets/${idObj._id}`)
                .pipe(
                  map((cabinets) => {
                    // CabinetActions.deleteCabinetSuccess({ id: idObj._id });
                    this.offlineDb.deleteByKey('Cabinet_deleted', idObj._id);
                    this.deletedCabinetsDone = true;
                    this.isSyncFinished();
                  }),
                  // catchError((error) =>
                  //   of(CabinetActions.deleteCabinetFailure({ error })),
                  // ),
                );
            });
          });
      }
      // ---------- new CabinetImages ------------- //
      if (!this.createdCabinetImagesDone) {
        console.log('SYNC new CabinetImages available.');
        this.offlineDb
          .getAll<IdxedCabinetImg>('CabinetImage_created')
          .subscribe((result) => {
            result.forEach((element) => {
              this.http
                .get(
                  `${this.apiUrl}/cabinets/images/${element.cabinetId}/${element._id}`,
                )
                .subscribe((imgEntry) => {
                  this.offlineDb.update<ImageEntry>('CabinetImage', {
                    _id: element._id,
                    imgFile: imgEntry,
                  } as ImageEntry);
                  this.offlineDb
                    .delete('CabinetImage_created', element._id)
                    .subscribe((offlineImages) => {
                      if (offlineImages.length == 0) {
                        this.createdCabinetImagesDone = true;
                        this.isSyncFinished();
                      }
                    });
                });
            });
          });
        //   this.http
        //     .post<Cabinet[]>(`${this.apiUrl}/cabinets/amount`, result)
        //     .pipe(
        //       map((cabinets) => {
        //         console.log(
        //           `SYNC:cabinets_created result[${cabinets.length}]`,
        //         );
        //         if (result.length == cabinets.length) {
        //           const ids = result
        //             .filter((c) => c._id !== undefined)
        //             .map((c) => c._id) as Key[];
        //           console.log(`SYNC:cabinets_created ids:`, ids);
        //           this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
        //           this.offlineDb.bulkDelete('Cabinet_created', ids);
        //         }
        //         this.createdCabinetsDone = true;
        //         this.isSyncFinished();
        //       }),
        //     ),
        // );
      }
      // ---------- deleted CabinetImages --------- //
      if (!this.deletedCabinetImagesDone) {
        console.log('SYNC deleted CabinetImages available.');
        this.offlineDb
          .getAll<{ _id: string; imgId: string }>('CabinetImage_deleted')
          .subscribe((result) => {
            result?.forEach((element) => {
              this.http
                .delete<Cabinet>(
                  `${this.apiUrl}/cabinets/images/${element.imgId}`,
                )
                .subscribe((cabinet) => {
                  this.offlineDb.update<Cabinet>('Cabinet', cabinet);
                  this.offlineDb
                    .delete('CabinetImage_deleted', element._id)
                    .subscribe((res) => {
                      if (res.length == 0) {
                        this.deletedCabinetImagesDone = true;
                        this.isSyncFinished();
                      }
                    });
                });
            });
          });
      }
      // ---------- new Evaluations --------------- //
      if (!this.createdEvaluationsDone) {
        console.log('SYNC new Evaluations available.');
        this.offlineDb
          .getAll<Evaluation>('Evaluation_created')
          .subscribe((offlineRes) => {
            this.http
              .post<Evaluation[]>(
                `${this.apiUrl}/evaluations/amount`,
                offlineRes,
              )
              .pipe(
                map((onlineRes) => {
                  console.log(
                    `SYNC:evaluations_created result[${onlineRes.length}]`,
                  );
                  if (offlineRes.length == onlineRes.length) {
                    const ids = offlineRes
                      .filter((c) => c._id !== undefined)
                      .map((c) => c._id) as Key[];
                    console.log(`SYNC:evaluations_created ids:`, ids);
                    this.offlineDb.bulkPut<Evaluation>('Evaluation', onlineRes);
                    this.offlineDb.bulkDelete('Evaluation_created', ids);
                  }
                  this.createdEvaluationsDone = true;
                  this.isSyncFinished();
                }),
              );
          });
      }
      // ---------- updated Evaluations ----------- //
      if (!this.updatedEvaluationsDone) {
        console.log('SYNC updated Evaluations available.');
        this.offlineDb
          .getAll<ImageEntry>('Evaluation_updated')
          .subscribe((resultSet) => {
            const evaluationsWithImgs = resultSet.filter(
              (c) =>
                (c as ImageEntry)._id !== undefined &&
                (c as ImageEntry).imgFile !== undefined,
            );
            const idsEvaluation = resultSet
              .filter(
                (c) =>
                  (c as ImageEntry)._id !== undefined &&
                  (c as ImageEntry).imgFile === undefined,
              )
              .map((c) => (c as ImageEntry)._id);
            console.log(
              `SYNC:evaluations without images [${idsEvaluation.length}]`,
              idsEvaluation,
            );
            this.offlineDb
              .bulkGet('Evaluation', idsEvaluation)
              .subscribe((resEvaluations: unknown) => {
                console.log(
                  `SYNC:evaluations from Offline data:`,
                  resEvaluations,
                );
                this.http
                  .patch<Evaluation[]>(
                    `${this.apiUrl}/evaluations/amount/`,
                    resEvaluations,
                  )
                  .subscribe((results) => {
                    if ((resEvaluations as []).length == results.length) {
                      console.log(
                        `SYNC:evaluations_updated [${results.length}] ids:`,
                      );
                      this.offlineDb.bulkDelete(
                        'Evaluation_updated',
                        idsEvaluation,
                      );
                      this.updatedEvaluationsDone = true;
                      this.isSyncFinished();
                    }
                  });
              });
            this.createdEvaluationImagesDone =
              evaluationsWithImgs.length > 0 ? false : true;
            let successResponse = 0;
            let failerResponse = 0;
            for (let i = 0; i < evaluationsWithImgs.length; i += 1) {
              const imgEvaluation = evaluationsWithImgs[i];
              console.log(
                `SYNC [${evaluationsWithImgs.length}] updated Evaluations with Images available.`,
              );
              this.offlineDb
                .getByID('Evaluation', imgEvaluation._id)
                .subscribe((localEvaluation) => {
                  const formData = new FormData();
                  formData.append('file', imgEvaluation.imgFile);
                  formData.append('fileName', imgEvaluation.imgFile.name);
                  formData.append(
                    'evaluation',
                    JSON.stringify(localEvaluation as Evaluation),
                  );
                  this.http
                    .post(`${this.apiUrl}/evaluations/images/`, formData, {
                      reportProgress: true,
                      observe: 'events',
                    })
                    .subscribe((event) => {
                      if (event.type == HttpEventType.Response) {
                        if (typeof event.body == typeof HttpErrorResponse) {
                          failerResponse += 1;
                        }
                        if (event.body !== null) {
                          successResponse += 1;
                        }
                        if (
                          i == evaluationsWithImgs.length - 1 &&
                          i == successResponse
                        ) {
                          this.createdEvaluationImagesDone = true;
                          this.isSyncFinished();
                        }
                      }
                    });
                });
            }
          });
      }
      // ---------- deleted Evaluations ----------- //
      if (!this.deletedEvaluationsDone) {
        console.log('SYNC deleted Evaluations available.');
        this.offlineDb
          .getAll<{ _id: string }>('Evaluation_deleted')
          .subscribe((result) => {
            console.log(`SYNC: Evaluation_deleted result[${result.length}]`);
            result.forEach((idObj) => {
              this.http
                .delete<Evaluation>(`${this.apiUrl}/evaluations/${idObj._id}`)
                .pipe(
                  map((evaluations) => {
                    this.offlineDb.deleteByKey('Evaluation_deleted', idObj._id);
                    this.deletedEvaluationsDone = true;
                    this.isSyncFinished();
                  }),
                );
            });
          });
      }
      // ---------- new EvaluationImages ---------- //
      if (!this.createdEvaluationImagesDone) {
        console.log('SYNC new EvaluationImages available.');
        this.offlineDb
          .getAll<IdxedEvaluationImg>('EvaluationImage_created')
          .subscribe((result) => {
            result.forEach((element) => {
              this.http
                .get(
                  `${this.apiUrl}/evaluations/images/${element.evaluationId}/${element._id}`,
                )
                .subscribe((imgEntry) => {
                  this.offlineDb.update<ImageEntry>('EvaluationImage', {
                    _id: element._id,
                    imgFile: imgEntry,
                  } as ImageEntry);
                  this.offlineDb
                    .delete('EvaluationImage_created', element._id)
                    .subscribe((offlineImages) => {
                      if (offlineImages.length == 0) {
                        this.createdEvaluationImagesDone = true;
                        this.isSyncFinished();
                      }
                    });
                });
            });
          });
      }
      // ---------- deleted EvaluationImages ------ //
      if (!this.deletedEvaluationImagesDone) {
        console.log('SYNC deleted EvaluationImages available.');
        this.offlineDb
          .getAll<{ _id: string; imgId: string }>('EvaluationImage_deleted')
          .subscribe((result) => {
            result?.forEach((element) => {
              this.http
                .delete<Evaluation>(
                  `${this.apiUrl}/evaluations/images/${element.imgId}`,
                )
                .subscribe((evaluation) => {
                  this.offlineDb.update<Evaluation>('Evaluation', evaluation);
                  this.offlineDb
                    .delete('EvaluationImage_deleted', element._id)
                    .subscribe((res) => {
                      if (res.length == 0) {
                        this.deletedEvaluationImagesDone = true;
                        this.isSyncFinished();
                      }
                    });
                });
            });
          });
      }
      // ---------- new Templates ----------------- //
      if (!this.createdTemplatesDone) {
        console.log('SYNC new Templates available.');
        this.offlineDb
          .getAll<LowTemplate>('Template_created')
          .subscribe((result) =>
            this.http
              .post<Template[]>(`${this.apiUrl}/templates/amount`, result)
              .pipe(
                map((templates) => {
                  console.log(
                    `SYNC:templates_created result[${templates.length}]`,
                  );
                  if (result.length == templates.length) {
                    const ids = result
                      .filter((c) => c._id !== undefined)
                      .map((c) => c._id) as Key[];
                    console.log(`SYNC:templates_created ids:`, ids);
                    this.offlineDb.bulkDelete('Template_created', ids);
                  }
                  this.createdTemplatesDone = true;
                  this.isSyncFinished();
                }),
              ),
          );
      }
      // ---------- updated Templates ------------- //
      if (!this.updatedTemplatesDone) {
        console.log('SYNC updated Templates available.');
        this.offlineDb
          .getAll<{ _id: string }>('Template_updated')
          .subscribe((resultSet) => {
            const idsTemplates = resultSet
              .filter((c) => c._id !== undefined)
              .map((c) => c._id);
            console.log(
              `SYNC:templates  [${idsTemplates.length}]`,
              idsTemplates,
            );
            this.offlineDb
              .bulkGet('Template', idsTemplates)
              .subscribe((resTemplates: unknown) => {
                console.log(`SYNC:templates from Offline data:`, resTemplates);
                this.http
                  .patch<Template[]>(
                    `${this.apiUrl}/templates/amount/`,
                    resTemplates,
                  )
                  .subscribe((result) => {
                    if ((resTemplates as []).length == result.length) {
                      console.log(
                        `SYNC:templates_updated [${result.length}] ids:`,
                      );
                      this.offlineDb.bulkDelete(
                        'Template_updated',
                        idsTemplates,
                      );
                      this.updatedTemplatesDone = true;
                      this.isSyncFinished();
                    }
                  });
              });
          });
      }
      // ---------- deleted Templates ------------- // ??
    }
  }

  offlineUpdates() {
    return (
      !this.updatedCabinetsDone ||
      !this.updatedTemplatesDone ||
      !this.updatedEvaluationsDone ||
      !this.deletedCabinetsDone ||
      !this.deletedTemplatesDone ||
      !this.deletedEvaluationsDone ||
      !this.deletedCabinetImagesDone ||
      !this.deletedEvaluationImagesDone ||
      !this.createdCabinetsDone ||
      !this.createdTemplatesDone ||
      !this.createdEvaluationsDone ||
      !this.createdCabinetImagesDone ||
      !this.createdEvaluationImagesDone
    );
  }

  isSyncFinished() {
    const updatesDone = !this.offlineUpdates();
    this.isInSync$.next(!updatesDone && this.isOnline$.value);
    console.info('isSyncFinished() => finised:', updatesDone);
    if (updatesDone) {
      console.info('isSyncFinished() => finised => load elements...');
      this.loadCabinets();
      // this.loadTemplates();
      this.loadEvaluations();
    }
    return !updatesDone;
  }

  clearOfflineData() {
    this.offlineDb.clear('Cabinet');
    this.offlineDb.clear('Cabinet_created');
    this.offlineDb.clear('Cabinet_deleted');
    this.offlineDb.clear('Cabinet_updated');
    this.offlineDb.clear('CabinetImage');
    this.offlineDb.clear('CabinetImage_created');
    this.offlineDb.clear('CabinetImage_deleted');
    this.offlineDb.clear('Cabinet_importFiles');
    this.offlineDb.clear('Evaluation');
    this.offlineDb.clear('Evaluation_created');
    this.offlineDb.clear('Evaluation_deleted');
    this.offlineDb.clear('Evaluation_updated');
    this.offlineDb.clear('EvaluationImage');
    this.offlineDb.clear('EvaluationImage_created');
    this.offlineDb.clear('EvaluationImage_deleted');
    this.offlineDb.clear('Template');
    this.offlineDb.clear('Template_created');
    this.offlineDb.clear('Template_deleted');
    this.offlineDb.clear('Template_updated');
  }

  // ------------------ Cabinet ------------------- //
  // ------------------ Cabinet (Multi) ----------- //

  loadCabinets(): Subject<Cabinet[]> {
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    console.log(`loadCabinets()`);
    const res1 = this.offlineDb.getAll<Cabinet>('Cabinet');
    const res2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
    const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
    res.subscribe((offlineCabinets) => {
      result.next(offlineCabinets);
      if (this.isOnline$.value && !this.offlineUpdates()) {
        console.log(`GET /cabinets/all/ `);
        this.http
          .get<Cabinet[]>(`${this.apiUrl}/cabinets/all/`)
          .subscribe((onlineCabinets) => {
            const deleted: string[] = offlineCabinets
              .filter((off) => !onlineCabinets.find((on) => off._id == on._id))
              .map((cabinet) => cabinet._id);
            const updated: Cabinet[] = onlineCabinets.filter(
              (on) => !offlineCabinets.find((off) => on._id == off._id),
            );
            onlineCabinets.forEach((onlineCabinet) => {
              const offlineCabinet = offlineCabinets.find(
                (offline) => offline._id == onlineCabinet._id,
              );
              if (
                offlineCabinet !== undefined &&
                !compareCabinets(onlineCabinet, offlineCabinet)
              ) {
                updated.push(onlineCabinet);
              }
            });
            if (deleted.length > 0) {
              this.offlineDb.bulkDelete('Cabinet', deleted);
            }
            if (updated.length > 0) {
              this.offlineDb
                .bulkPut<Cabinet>('Cabinet', updated)
                .subscribe((offlineRes) => {
                  console.log(
                    `OFFLINE PATCH /evaluations/all/ => get all Evaluations Key:`,
                    offlineRes,
                  );
                });
            }
            if (deleted.length > 0 || updated.length > 0) {
              const r1 = this.offlineDb.getAll<Cabinet>('Cabinet');
              const r2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
              const resSum = merge(r1, r2).pipe(reduce((a, b) => a.concat(b)));
              resSum.subscribe((r) => result.next(r));
            } else {
              result.next([]);
            }
          });
      } else {
        result.next(offlineCabinets);
      }
    });
    return result;
  }

  // ------------------ Cabinet (Single) ---------- //
  loadCabinet(id: string): Subject<Cabinet> {
    console.log(`loadCabinet(${id})`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    this.offlineDb.getByID('Cabinet', id).subscribe((c) => {
      if (!c) {
        this.offlineDb.getByID('Cabinet_created', id).subscribe((c1) => {
          result.next(c1 as Cabinet);
        });
      } else {
        result.next(c as Cabinet);
      }
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /cabinets/${id}`);
      this.http
        .get<Cabinet>(`${this.apiUrl}/cabinets/${id}`)
        .subscribe((cabinet) => {
          result.next(cabinet);
          this.offlineDb.update<Cabinet>('Cabinet', cabinet);
        });
    }
    return result;
  }

  postCabinet(cabinet: LowCabinet): Subject<Cabinet> {
    console.log(`postCabinet()`, cabinet);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log('POST /cabinets/ ');
      this.http
        .post<Cabinet>(`${this.apiUrl}/cabinets/`, cabinet)
        .subscribe((onlineCabinet) => {
          console.log(`OFFLINE sync (POST) cabinet:`, onlineCabinet);
          const obs = this.offlineDb.update<Cabinet>('Cabinet', onlineCabinet);
          obs.subscribe((res) => result.next(res));
          //   console.error('postCabinet() GOT RESULT(offline):', res),
          // );
          // result.next(onlineCabinet);
          // this.offlineDb.update<Cabinet>('Cabinet', onlineCabinet).pipe(
          //   map((res) => {
          //     console.error('postCabinet() Offline-sync:', res);
          //     result.next(onlineCabinet);
          //   }),
          //   catchError(async (error) =>
          //     console.error('something went wrong : ', error),
          //   ),
          // );
          // .subscribe((offlineCab) => {
          //   console.error('postCabinet() Offline-sync:', offlineCab);
          //   result.next(offlineCab);
          //   // });
          //   // result.next(onlineCabinet);
        });
    } else {
      this.createdCabinetsDone = false;
      cabinet._id = this.createOfflineId();
      this.offlineDb.update('Cabinet_created', cabinet).subscribe((offline) => {
        // offline._id = '0';
        console.log(`OFFLINE POST /cabinets/:${offline._id}/`);
        result.next(offline as Cabinet);
      });
    }
    return result;
  }

  patchCabinet(cabinet: Cabinet): Subject<Cabinet> {
    console.log(`patchCabinet(${cabinet._id})`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    const idCabinet = cabinet._id;
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`PATCH /cabinets/:${cabinet._id}`);
      this.http
        .patch<Cabinet>(`${this.apiUrl}/cabinets/${cabinet._id}`, cabinet)
        .subscribe((online) => {
          result.next(online);
          this.offlineDb.update<Cabinet>('Cabinet', online);
        });
    } else {
      try {
        if (idCabinet.startsWith('offline')) {
          this.offlineDb
            .update('Cabinet_created', cabinet)
            .subscribe((offline) => {
              console.log(`PATCH /cabinets/:${offline._id} (created)`, offline);
              result.next(offline);
            });
        } else {
          this.updatedCabinetsDone = false;
          this.offlineDb.update('Cabinet', cabinet).subscribe((offline) => {
            console.log(`PATCH /cabinets/:${offline._id} (update)`, offline);
            this.offlineDb
              .getByID<Cabinet>('Cabinet', offline._id)
              .subscribe((offCab) => {
                console.log(`PATCH /cabinets/:${offCab._id} (getById)`, offCab);
                result.next(offCab);
              });
          });
          this.offlineDb
            .update('Cabinet_updated', { _id: cabinet._id })
            .subscribe((offline) => {
              console.log(
                `PATCH /cabinets/:${cabinet._id} Cabinet_updated added id:`,
                offline,
              );
            });
        }
      } catch (err) {
        console.error('PATCH /cabinets/:  ERROR:', err);
      }
    }
    return result;
  }

  deleteCabinet(id: string): Subject<Cabinet[]> {
    console.log(`deleteCabinet(${id})`);
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    this.offlineDb.delete('Cabinet', id).subscribe((deleted) => {
      console.log(`OFFLINE DELETE /cabinets/:${id}/`, deleted);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`DELETE /cabinets/:${id}`);
      this.http
        .delete<Cabinet[]>(`${this.apiUrl}/cabinets/${id}`)
        .subscribe((cabinets) => {
          this.offlineDb.clear('Cabinet');
          this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
          result.next(cabinets);
        });
    } else {
      if (id.startsWith('offline')) {
        this.offlineDb.delete('Cabinet_created', id).subscribe((del) => {
          console.log(`OFFLINE DELETE /cabinets/:${id}/ (new)`, del);
          const cabs1 = this.offlineDb.getAll<Cabinet>('Cabinet');
          const cabs2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
          merge(cabs1, cabs2).subscribe((c) => {
            result.next(c);
          });
        });
      } else {
        this.deletedCabinetsDone = false;
        this.offlineDb.delete('Cabinet', id).subscribe((deleted) => {
          console.log(`OFFLINE DELETE /cabinets/:${id}/ (existing)`, deleted);
          this.offlineDb.update('Cabinet_deleted', { _id: id });
          const cabs1 = this.offlineDb.getAll<Cabinet>('Cabinet');
          const cabs2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
          merge(cabs1, cabs2).subscribe((c) => {
            result.next(c);
          });
        });
      }
    }
    return result;
  }

  // -------------- Cabinet.Image ----------------- //
  // ------- Cabinet- & Evaluation images --------- //

  loadCabinetImage(cabinetId: string, imageId: string): Observable<any> {
    console.log(`loadCabinetImage(${cabinetId}, ${imageId})`);
    const result: Subject<any> = new Subject<any>();
    const res1 = this.offlineDb.getAll<ImageEntry>('Cabinet_updated');
    const res2 = this.offlineDb.getAll<ImageEntry>('CabinetImage');
    merge(res1, res2)
      .pipe(reduce((a, b) => a.concat(b)))
      .subscribe((imgEntries) => {
        const returnValue = imgEntries.find(
          (imgFile) => imgFile._id == imageId,
        );
        console.log(
          `GET /cabinets/images/ count of results:`,
          imgEntries.length,
        );
        result.next(returnValue?.imgFile);
      });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /cabinets/images/${cabinetId}/${imageId}`);
      this.http
        .get(`${this.apiUrl}/cabinets/images/${cabinetId}/${imageId}`)
        .subscribe((imgEntry) => {
          this.offlineDb
            .update<ImageEntry>('CabinetImage', {
              _id: imageId,
              imgFile: imgEntry,
            } as ImageEntry)
            .subscribe((offlineRes) => {
              result.next(offlineRes?.imgFile);
              console.log(`loadCabinetImage() offline-Key:`, offlineRes);
            });
        });
    }
    return result;
  }

  loadEvaluationImage(evaluationId: string, imageId: string): Observable<any> {
    const result: Subject<any> = new Subject<any>();
    console.log(`loadEvaluationImage(${evaluationId}, ${imageId})`);
    const res1 = this.offlineDb.getAll<ImageEntry>('Evaluation_updated');
    const res2 = this.offlineDb.getAll<ImageEntry>('EvaluationImage');
    merge(res1, res2)
      .pipe(reduce((a, b) => a.concat(b)))
      .subscribe((imgEntries) => {
        const returnValue = imgEntries.find(
          (imgFile) => imgFile._id == imageId,
        );
        console.log(
          `GET /evaluations/images/ count of results:`,
          imgEntries.length,
        );
        result.next(returnValue?.imgFile);
      });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /evaluations/images/${evaluationId}/${imageId}`);
      this.http
        .get(`${this.apiUrl}/evaluations/images/${evaluationId}/${imageId}`)
        .subscribe((imgEntry) => {
          this.offlineDb
            .update<ImageEntry>('EvaluationImage', {
              _id: imageId,
              imgFile: imgEntry,
            } as ImageEntry)
            .subscribe((offlineRes) => {
              result.next(offlineRes?.imgFile);
              console.log(`loadEvaluationImage() offline-Key:`, offlineRes);
            });
        });
    }
    return result;
    // // only online
    // console.log(`GET /evaluations/images/${evaluationId}/${imageId}`);
    // return this.http.get(
    //   `${this.apiUrl}/evaluations/images/${evaluationId}/${imageId}`,
    // );
  }

  postCabinetImage(data: CheckObj, cabinet: Cabinet): Subject<Cabinet> {
    console.log(`postCabinetImage((${data.file.name}, ${cabinet?._id})`);
    const imgFile = data.file as extendFile; // ???
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(
        `POST /cabinets/images/ File(${data.file.name}) Cabinet(${cabinet._id})`,
      );
      let resCabinet: Cabinet = cabinet;
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('fileName', data.file.name.replace('.', ''));
      formData.append('cabinet', JSON.stringify(cabinet));
      this.http
        .post(`${this.apiUrl}/cabinets/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resCabinet = event.body as Cabinet;
              const onlineImg = resCabinet.Images?.find(
                (i) => i.Name == data.file.name.replace('.', ''),
              );
              console.warn(
                '[Online].postCabinetImage() online-image?:',
                onlineImg,
              );
              if (onlineImg) {
                imgFile.arrayBuffer().then((result) => {
                  const base64Img = btoa(
                    String.fromCharCode(...new Uint8Array(result)),
                  );
                  const b64 = base64Img.substring(0, 25);
                  console.log('[Online].postCabinetImage() base64:', b64);
                  this.offlineDb
                    .update('CabinetImage', {
                      _id: onlineImg._id,
                      imgFile: base64Img,
                    })
                    .subscribe((r) => {
                      console.log(
                        '[Online].postCabinetImage() offline-image?:',
                        r,
                      );
                    });
                });
              }
              this.offlineDb
                .update<Cabinet>('Cabinet', resCabinet)
                .subscribe(() => result.next(resCabinet));
            }
          }
        });
    } else {
      this.createdCabinetImagesDone = false;
      let offlineId = this.createOfflineId();
      // first create offline-Image
      const imgFile = data.file as extendFile; // ???
      this.offlineDb
        .update('CabinetImage_created', {
          cabinetId: cabinet._id,
          imgFile: data.file,
        })
        .subscribe((r) => {
          console.warn('[Offline].postCabinetImage() offline-image?:', r);
          try {
            offlineId = (r as unknown as ImageEntry)?._id;
            console.warn('[Offline].postCabinetImage() offlineId:', offlineId);
            cabinet.Images?.push({
              _id: offlineId,
              Path: '',
              InternalName: imgFile.name,
              Name: imgFile.name,
              Original: imgFile.name.split('.')[0],
              Extension: imgFile.name.split('.').pop(),
              Encoding: imgFile.encoding,
              MIME: imgFile.mimetype,
              Size: imgFile.size,
              AsPreview: false,
              Thumb: data.file,
            } as CabinetImg);
          } catch (err) {
            console.error(`[Offline].postCabinetImage() ERROR:`, err);
          }
        });
      // now update offline-Cabinet, which contains the new image
      this.offlineDb.update('Cabinet', cabinet).subscribe((offlineResult) => {
        console.log(
          `[Offline].postCabinetImage() cabinet:${offlineResult._id}`,
        );
        result.next(offlineResult);
      });
      this.offlineDb.update('Cabinet_updated', {
        _id: cabinet._id,
        imgFile: data.file,
      });
    }
    return result;
  }

  postEvaluationImage(
    data: CheckObj,
    evaluation: Evaluation,
  ): Subject<Evaluation> {
    console.log(`postEvaluationImage(${data.file.name}, ${evaluation?._id})`);
    const imgFile = data.file as extendFile; // ???
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(
        `POST /evaluations/images/ File(${data.file.name}) Cabinet(${evaluation._id})`,
      );
      let resEvaluation: Evaluation = evaluation;
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('fileName', data.file.name.replace('.', ''));
      formData.append('evaluation', JSON.stringify(evaluation));
      this.http
        .post(`${this.apiUrl}/evaluations/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resEvaluation = event.body as Evaluation;
              const onlineImg = resEvaluation.Images?.find(
                (i) => i.Name == data.file.name.replace('.', ''),
              );
              console.log(
                '[Online].postEvaluationImage() online-image?:',
                onlineImg,
              );
              if (onlineImg) {
                imgFile.arrayBuffer().then((result) => {
                  const base64Img = btoa(
                    String.fromCharCode(...new Uint8Array(result)),
                  );
                  const b64 = base64Img.substring(0, 25);
                  console.log('[Online].postEvaluationImage() base64:', b64);
                  this.offlineDb
                    .update('EvaluationImage', {
                      _id: onlineImg._id,
                      imgFile: base64Img,
                    })
                    .subscribe((r) => {
                      console.warn(
                        '[Online].postEvaluationImage() offline-image?:',
                        r,
                      );
                    });
                });
              }
              this.offlineDb
                .update<Evaluation>('Evaluation', resEvaluation)
                .subscribe(() => result.next(resEvaluation));
            }
          }
        });
    } else {
      this.createdEvaluationImagesDone = false;
      let offlineId = this.createOfflineId();
      // first create offline-Image
      const imgFile = data.file as extendFile; // ???
      this.offlineDb
        .add('EvaluationImage_created', {
          evaluationId: evaluation._id,
          imgFile: data.file,
        })
        .subscribe((r) => {
          console.warn('[Offline].postEvaluationImage() offline-image:', r);
          try {
            offlineId = (r as unknown as ImageEntry)?._id;
            console.warn(
              '[Offline].postEvaluationImage() offlineId:',
              offlineId,
            );
            evaluation.Images?.push({
              _id: offlineId,
              Path: '',
              InternalName: imgFile.name,
              Name: imgFile.name,
              Original: imgFile.name.split('.')[0],
              Extension: imgFile.name.split('.').pop(),
              Encoding: imgFile.encoding,
              MIME: imgFile.mimetype,
              Size: imgFile.size,
              Thumb: data.file,
            } as BaseImage);
          } catch (err) {
            console.error(`[Offline].postEvaluationImage() ERROR:`, err);
          }
        });
      // now update offline-Evaluation, which contains the new image
      this.offlineDb.update('Evaluation', evaluation).subscribe((offline) => {
        console.log(
          `[Offline].postEvaluationImage() evaluations:${offline._id}`,
        );
        result.next(offline);
      });
      this.offlineDb.update('Evaluation_updated', {
        _id: evaluation._id,
        imgFile: data.file,
      });
    }
    return result;
  }

  deleteCabinetImage(cabinetId: string, id: string): Subject<Cabinet> {
    console.log(`DELETE /cabinets/images/${cabinetId}/${id}`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`deleteCabinetImage(${cabinetId}, ${id})`);
      this.http
        .delete<Cabinet>(`${this.apiUrl}/cabinets/images/${cabinetId}/${id}`)
        .subscribe((cabinet) => {
          result.next(cabinet);
          this.offlineDb.update<Cabinet>('Cabinet', cabinet);
        });
    } else {
      this.offlineDb
        .getByID<Cabinet>('Cabinet', cabinetId)
        .subscribe((cabinet) => {
          cabinet;
          const img = cabinet.Images?.find((i) => i._id == id);
          if (img) {
            const index = cabinet.Images!.indexOf(img);
            if (index > -1) {
              this.deletedCabinetImagesDone = false;
              cabinet.Images!.splice(index, 1); // 2nd parameter means remove one item only
              this.offlineDb.update<Cabinet>('Cabinet', cabinet);
              this.offlineDb.update<{ _id: string; imgId: string }>(
                'CabinetImage_deleted',
                { _id: cabinetId, imgId: id },
              );
              result.next(cabinet);
            }
          }
        });
    }
    return result;
  }

  deleteEvaluationImage(evaluationId: string, id: string): Subject<Evaluation> {
    console.log(`deleteEvaluationImage(${evaluationId}, ${id})`);
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`DELETE /evaluations/images/${evaluationId}/${id}`);
      this.http
        .delete<Evaluation>(
          `${this.apiUrl}/evaluations/images/${evaluationId}/${id}`,
        )
        .subscribe((evaluation) => {
          result.next(evaluation);
          this.offlineDb.update<Evaluation>('Evaluation', evaluation);
        });
    } else {
      this.offlineDb
        .getByID<Evaluation>('Evaluation', evaluationId)
        .subscribe((evaluation) => {
          evaluation;
          const img = evaluation.Images?.find((i) => i._id == id);
          if (img) {
            const index = evaluation.Images!.indexOf(img);
            if (index > -1) {
              this.deletedEvaluationImagesDone = false;
              evaluation.Images!.splice(index, 1); // 2nd parameter means remove one item only
              this.offlineDb.update<Evaluation>('Evaluation', evaluation);
              this.offlineDb.update<{ _id: string; imgId: string }>(
                'EvaluationImage_deleted',
                { _id: evaluationId, imgId: id },
              );
              result.next(evaluation);
            }
          }
        });
    }
    return result;
  }

  // --------------- Cabinet.ExcelFile ------------ //

  // nur in online-modus nutzen !
  postCabinetExcelFile(
    file: File,
    fileName: string,
    template: Template,
  ): Subject<Cabinet[]> {
    console.log(
      `postCabinetExcelFile(${file?.name}, ${fileName}, ${template?._id})`,
    );
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log('POST /cabinets/exelfiles/');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', fileName);
      formData.append('template', JSON.stringify(template));
      this.http
        .post(`${this.apiUrl}/cabinets/exelfiles/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              const cabinets = event.body as Cabinet[];
              this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
              result.next(cabinets);
            }
          }
        });
    } else {
      // TODO Offline ???
    }
    return result;
  }

  // ------ Evaluation (Cabinet.Ergebnis)---------- //
  loadNewEvaluationsFor(
    cabinetId: string | null | undefined,
    tempalteId: string | null | undefined,
  ): Subject<Evaluation> {
    console.log(`loadNewEvaluationsFor(${cabinetId}, ${tempalteId})`);
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    if (cabinetId && tempalteId) {
      if (this.isOnline$.value && !this.offlineUpdates()) {
        console.log(`online GET /evaluations/new/ `);
        this.http
          .get<Evaluation>(
            `${this.apiUrl}/evaluations/new/${cabinetId}/${tempalteId}`,
          )
          .subscribe((evaluation) => {
            result.next(evaluation);
            this.offlineDb
              .update<Evaluation>('Evaluation', evaluation)
              .subscribe((result) => {
                console.log(
                  `OFFLINE PATCH /evaluations/new/ => result:`,
                  result,
                );
              });
            console.log(`GET /evaluations/new/ => newId:`, evaluation._id);
          });
      } else {
        console.log('OFFLINE GET /evaluations/new/ => : TODO');
        this.offlineDb
          .getByID<Cabinet>('Cabinet_created', cabinetId)
          .subscribe((c) => {
            // wenn offline neu angelegt (gefunden)
            if (c) {
              this.offlineDb
                .getByID<Template>('Template', tempalteId) // neu erstellte Templates ????
                .subscribe((t) => {
                  if (t) {
                    this.createdEvaluationsDone = false;
                    const evaluation = this.createEvaluatinFor(c, t);
                    result.next(evaluation);
                    this.offlineDb
                      .update('Evaluation_created', evaluation)
                      .subscribe((updated) => {
                        console.log(
                          'OFFLINE GET /evaluations/new/ => : evaluation',
                          updated,
                        );
                      }); // offlineEvaluation
                  } else {
                    console.error(
                      'loadNewEvaluationsFor() Template is undefined!',
                    );
                  }
                });
              // wenn in 'Cabinet_created' NICHT gefunden, dann offline in 'Cabinet'
            } else {
              this.offlineDb
                .getByID<Cabinet>('Cabinet', cabinetId)
                .subscribe((c) => {
                  this.offlineDb
                    .getByID<Template>('Template', tempalteId) // neu erstellte Templates ????
                    .subscribe((t) => {
                      if (t) {
                        this.createdEvaluationsDone = false;
                        const evaluation = this.createEvaluatinFor(c, t);
                        result.next(evaluation);
                        this.offlineDb
                          .update('Evaluation_created', evaluation)
                          .subscribe((updated) => {
                            console.log(
                              'OFFLINE GET /evaluations/new/ => : evaluation',
                              updated,
                            );
                          }); // offlineEvaluation
                      } else {
                        console.error(
                          'loadNewEvaluationsFor() Template is undefined!',
                        );
                      }
                    });
                });
            }
          });
        // this.offlineDb.getByID<Cabinet>('Cabinet', cabinetId).subscribe((c) => {
        //   this.offlineDb
        //     .getByID<Template>('Template', tempalteId)
        //     .subscribe((t) => {
        //       this.createdEvaluationsDone = false;
        //       const evaluation = this.createEvaluatinFor(c, t);
        //       result.next(evaluation);
        //       this.offlineDb
        //         .update('Evaluation_created', evaluation)
        //         .subscribe((updated) => {
        //           console.log(
        //             'OFFLINE GET /evaluations/new/ => : evaluation',
        //             updated,
        //           );
        //         }); // offlineEvaluation
        //     });
        // });
        // TODO result generieren! ???
        // const res1 = this.dbService.getAll<Evaluation>('Evaluation');
        // const res2 = this.dbService.getAll<Evaluation>('Evaluation_created');
        // res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
        // res.subscribe((cabinets) => {
        //   console.log(
        //     `OFFLINE GET /cabinets/all/ => get all Cabinets counted:`,
        //     cabinets.length,
        //   );
        // });
      }
    } else {
      console.error(
        `GET /evaluations/new/:cabinetId/:tempalteId \n\tcabinetId should NOT be null!`,
      );
    }
    return result;
  }

  loadEvaluations(): Subject<Evaluation[]> {
    const result: Subject<Evaluation[]> = new Subject<Evaluation[]>();
    console.log(`loadEvaluations()`);
    const res1 = this.offlineDb.getAll<Evaluation>('Evaluation');
    const res2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
    const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
    res.subscribe((offEvaluations) => {
      result.next(offEvaluations);
      if (this.isOnline$.value && !this.offlineUpdates()) {
        console.log(`GET /evaluations/all/ `);
        this.http
          .get<[Evaluation]>(`${this.apiUrl}/evaluations/all/`)
          .subscribe((onEvaluations) => {
            const deleted: string[] = offEvaluations
              .filter((off) => !onEvaluations.find((on) => off._id == on._id))
              .map((cabinet) => cabinet._id);
            const updated: Evaluation[] = onEvaluations.filter(
              (on) => !offEvaluations.find((off) => on._id == off._id),
            );
            onEvaluations.forEach((onEvaluation) => {
              const offEvaluation = offEvaluations.find(
                (e) => e._id == onEvaluation._id,
              );
              if (
                offEvaluation !== undefined &&
                !compareEvaluations(onEvaluation, offEvaluation)
              ) {
                updated.push(onEvaluation);
              }
            });
            if (deleted.length > 0)
              this.offlineDb.bulkDelete('Evaluation', deleted);
            if (updated.length > 0)
              this.offlineDb
                .bulkPut<Evaluation>('Evaluation', updated)
                .subscribe((offlineRes) => {
                  console.log(
                    `OFFLINE PATCH /evaluations/all/ => get all Evaluations Key:`,
                    offlineRes,
                  );
                });
            if (deleted.length > 0 || updated.length > 0) {
              const r1 = this.offlineDb.getAll<Evaluation>('Evaluation');
              const r2 =
                this.offlineDb.getAll<Evaluation>('Evaluation_created');
              const resSum = merge(r1, r2).pipe(reduce((a, b) => a.concat(b)));
              resSum.subscribe((r) => result.next(r));
            }
          });
      }
    });
    return result;
  }

  loadEvaluation(id: string): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    console.log(`loadEvaluation(${id})`);
    this.offlineDb.getByID<Evaluation>('Evaluation', id).subscribe((e) => {
      if (!e) {
        this.offlineDb
          .getByID<Evaluation>('Evaluation_created', id)
          .subscribe((e1) => {
            result.next(e1);
          });
      } else {
        result.next(e);
      }
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /evaluations/${id}`);
      this.http
        .get<Evaluation>(`${this.apiUrl}/evaluations/${id}`)
        .subscribe((e) => {
          this.offlineDb
            .update<Evaluation>('Evaluation', e)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluations/${id} offline:`,
                offlineRes,
              );
            });
          result.next(e);
        });
    }
    return result;
  }

  loadEvaluationsByCabinet(cabinetId: string): Subject<Evaluation[]> {
    const result: Subject<Evaluation[]> = new Subject<Evaluation[]>();
    console.log(`loadEvaluationsByCabinet(${cabinetId})`);
    const res1 = this.offlineDb.getAll<Evaluation>('Evaluation');
    const res2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
    const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
    res.subscribe((e) => {
      const evaluations = e.filter(
        (evaluation) => evaluation.Cabinet._id == cabinetId,
      );
      if (evaluations) result.next(evaluations);
      else
        console.error(
          'loadEvaluationsByCabinet() didn`t find any offline-data',
        );
    });
    if (
      this.isOnline$.value &&
      !this.offlineUpdates() &&
      !cabinetId.startsWith('offline')
    ) {
      console.log(`GET /evaluations/byCabinet/${cabinetId}`);
      this.http
        .get<Evaluation[]>(`${this.apiUrl}/evaluations/byCabinet/${cabinetId}`)
        .subscribe((evaluations) => {
          this.offlineDb
            .bulkPut<Evaluation>('Evaluation', evaluations)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluations/byCabinet/${cabinetId} Key:`,
                offlineRes,
              );
            });
          result.next(evaluations);
        });
    }
    return result;
  }

  patchEvaluation(evaluation: Evaluation): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    console.log(`patchEvaluation(${evaluation._id})`);
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`PATCH /evaluations/${evaluation._id}`);
      this.http
        .patch<Evaluation>(
          `${this.apiUrl}/evaluations/${evaluation._id}`,
          evaluation,
        )
        .subscribe((httpEvaluation) => {
          console.log(`got result with Evaluation._id:`, httpEvaluation);
          this.offlineDb
            .update<Evaluation>('Evaluation', httpEvaluation)
            .subscribe((localEvaluations) => {
              console.log('OFFLINE PATCH /evaluations/:', localEvaluations);
            });
          result.next(httpEvaluation);
        });
    } else {
      if (evaluation._id.startsWith('offline')) {
        this.offlineDb
          .update('Evaluation_created', evaluation)
          .subscribe((offline) => {
            console.log(
              `[OFFLINE] PATCH /evaluations/:${offline._id}/ (created)`,
            );
            result.next(offline as Evaluation);
          });
      } else {
        this.updatedEvaluationsDone = false;
        this.offlineDb.update('Evaluation', evaluation).subscribe((offline) => {
          console.log(`OFFLINE PATCH /evaluations/:${offline._id}/ (existing)`);
          result.next(offline as Evaluation);
        });
        this.offlineDb.update('Evaluation_updated', { _id: evaluation._id });
      }
    }
    return result;
  }

  deleteEvaluation(cabinetId: string, id: string): Subject<Evaluation[]> {
    console.log(`deleteEvaluation(${id})`);
    const result: Subject<Evaluation[]> = new Subject<Evaluation[]>();
    // this.offlineDb.delete('Evaluation', id).subscribe((deleted) => {
    //   console.log(`OFFLINE DELETE /evaluations/:${id}/`, deleted);
    // });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`DELETE /evaluations/:${id}`);
      this.http
        .delete<Evaluation[]>(`${this.apiUrl}/evaluations/${id}`)
        .subscribe((evaluations) => {
          this.offlineDb.clear('Evaluation');
          this.offlineDb.bulkPut<Evaluation>('Evaluation', evaluations);
          result.next(evaluations);
        });
    } else {
      if (id.startsWith('offline')) {
        this.offlineDb.delete('Evaluation_created', id).subscribe((deleted) => {
          console.log(`OFFLINE DELETE /evaluations/:${id}/ (new)`, deleted);
          const cabs1 = this.offlineDb.getAll<Evaluation>('Evaluation');
          const cabs2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
          merge(cabs1, cabs2).subscribe((e) => {
            const evaluations = e.filter(
              (evaluation) => evaluation.Cabinet._id == cabinetId,
            );
            if (evaluations) result.next(evaluations);
            else
              console.error('deleteEvaluation() didn`t find any offline-data');
          });
        });
      } else {
        this.deletedEvaluationsDone = false;
        this.offlineDb.delete('Evaluation', id).subscribe((del) => {
          console.log(`OFFLINE DELETE /evaluations/:${id}/ (existing)`, del);
          this.offlineDb
            .update('Evaluation_deleted', { _id: id })
            .subscribe(() => {
              const cabs1 = this.offlineDb.getAll<Evaluation>('Evaluation');
              const cabs2 =
                this.offlineDb.getAll<Evaluation>('Evaluation_created');
              merge(cabs1, cabs2).subscribe((e) => {
                const evaluations = e.filter(
                  (evaluation) => evaluation.Cabinet._id == cabinetId,
                );
                if (evaluations) result.next(evaluations);
                else
                  console.error(
                    'deleteEvaluation() didn`t find any offline-data',
                  );
              });
            });
        });
      }
    }
    return result;
  }

  // TODO : ------------------ Offline-functions ----------

  getPdfEvaluation(id: string): Observable<any> {
    console.log(`GET /pdfresult/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluations/pdfresult/${id}`);
  }

  loadNewPdfFor(id: string): Observable<any> {
    console.log(`GET /pdfrequestfor/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluations/pdfrequestfor/${id}`);
  }

  getPdfEvaluationsFor(cabinetId: string): Observable<any> {
    console.log(`GET /evaluations/pdfresults/${cabinetId}`);
    return this.http.get(`${this.apiUrl}/evaluations/pdfresults/${cabinetId}`);
  }

  // ------------- Template (edit/create)---------- //

  /**
   * Is loading the Template for the user
   * @returns Observable<Template>
   */
  loadTemplate(): Subject<Template> {
    const res: Subject<Template> = new Subject<Template>();
    const offTmp1 = this.offlineDb.getAll<Template>('Template');
    const offTmp2 = this.offlineDb.getAll<Template>('Template_created');
    const offTmp = merge(offTmp1, offTmp2).pipe(reduce((a, b) => a.concat(b)));
    offTmp?.subscribe((templates) => {
      if (templates && templates.length > 1) {
        const actTemp = templates.reduce((a, b) => {
          if (a.createdAt > b.createdAt) return a;
          else return b;
        });
        console.log(`OFFLINE GET /templates/`, actTemp);
        res.next(actTemp);
      } else if (templates && templates.length == 1) {
        console.log('OFFLINE found one template', templates[0]);
        res.next(templates[0]);
      }
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /templates/`);
      this.http
        .get<Template[]>(`${this.apiUrl}/templates/all/`)
        .subscribe((templates) => {
          this.offlineDb
            .bulkPut<Template>('Template', templates)
            .subscribe((offRes) => {
              console.log(
                `OFFLINE PATCH /templates/all/ count:`,
                templates.length,
              );
              const actTemp = templates.reduce((a, b) => {
                if (a.createdAt > b.createdAt) return a;
                else return b;
              });
              res.next(actTemp);
            });
        });
    }
    return res;
  }

  patchTemplate(template: Template): Observable<Template> {
    console.log(`patchTemplate(${template._id})`, template);
    console.log(`patchTemplate(${template._id}) Online:`, this.isOnline$.value);
    console.log(`patchTemplate(${template._id}) Sync:`, this.isInSync$.value);
    const idTemplate = template._id;
    const result: Subject<Template> = new Subject<Template>();
    if (this.isOnline$.value && !this.isInSync$.value) {
      console.log(`[ONLINE]PATCH /templates/:${idTemplate}`);
      this.http
        .patch<Template>(`${this.apiUrl}/templates/${idTemplate}`, template)
        .subscribe((t) => {
          this.offlineDb.update('Template', t).subscribe((offline) => {
            console.log('postTemplate() result from off-DB:', offline);
            result.next(offline as Template);
          });
        });
    } else {
      this.updatedTemplatesDone = false;
      if (idTemplate.startsWith('offline')) {
        this.offlineDb
          .update('Template_created', template)
          .subscribe((offline) => {
            console.log('[OFFLINE]patchTemplate() for created');
            result.next(offline as Template);
          });
      } else {
        this.offlineDb.update('Template_updated', { id: idTemplate });
        this.offlineDb.update('Template', template).subscribe((offline) => {
          console.log('[OFFLINE]patchTemplate() for existing');
          result.next(offline as Template);
        });
      }
    }
    return result;
  }

  //  ---------------------   Utils  ---------------------
  createOfflineId(): string {
    return `offline_${new Date().getMilliseconds()}`;
  }

  createEvaluatinFor(cabinet: Cabinet, template: Template): Evaluation {
    const defaultEvaluation: Evaluation = {
      _id: this.createOfflineId(),
      Cabinet: cabinet,
      Template: template,
      Pruefung: [] as { ItemId: string; Value: object }[],
      Gefaehrdung: [] as { ItemId: string; Value: object }[],
      Page1: { Assesment: [] },
      Page2: {
        PruefItems: [],
        Reduktionsfaktor: '',
      },
      PDFResult: {
        createdAt: undefined,
        updatedAt: undefined,
        File: undefined,
      },
      Images: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    } as unknown as Evaluation;
    const res = defaultEvaluation;
    template.Pruefung.forEach((itm) => {
      defaultEvaluation.Pruefung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    template.Gefaehrdung.forEach((itm) => {
      defaultEvaluation.Gefaehrdung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    return res;
  }
}
