<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> /
        <a routerLink="/risks">Gefährdungsbeurteilung</a> /
        <a routerLink="/risks/review/{{ cabinet?._id }}">Anlage</a> / Daten
        bearbeiten
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="card">
  <!-- <h3 class="card-header">Header</h3> -->
  <div class="card-block">
    <h4 class="card-title">Anlage</h4>
    <div class="card-text">
      <clr-tabs>
        <!-- ANLAGE -->
        <clr-tab>
          <button clrTabLink>Anlage</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab-cabinet
              #anlageTab
              [cabinet]="cabinet"
              (updated)="refresh($event)"
              (init)="initAnlage($event)"
            ></app-risks-tab-cabinet>
            <!-- [templateItemArray$]="itmsAnlage$" -->
          </clr-tab-content>
        </clr-tab>
        <!-- FOTOS -->
        <clr-tab>
          <button clrTabLink>Fotos</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab-images-cabinet
              #imgTab
              [cabinet]="cabinet"
              (updated)="setCabinet($event)"
              (init)="initImages($event)"
            ></app-risks-tab-images-cabinet>
            <!-- [setItemArray]="setItemArray(itmsGefaehrdung$)" -->
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<!-- HELPER -->
<clr-modal [(clrModalOpen)]="helper">
  <h3 class="modal-title">{{ text | uppercase }}</h3>
  <div class="modal-body">
    <div *ngIf="text === 'schmutz'">
      Schmutz sollte nach Gefühl bewertet werden.
      <p>
        Grobe Verschmutzungen, welche den Betrieb gefährden sollten mit 6
        bewertet werden und erfordern über die Bewertung hinaus sofortigen
        Handlungsbedarf.
      </p>
      <p>Neuwertige Anlagen sollten mit 1 Bewertet werden.</p>
    </div>

    <div *ngIf="text === 'feuchtigkeit'">
      Feuchtgkeit sollte in Anlehnung an DIN EN 60529 bewertet werden.
    </div>

    <div *ngIf="text === 'alterung'">
      Alterung sollte in Anlehnung an die Übliche Lebensdauererwartung von
      Elektrischen Anlagen bewertet werden.
      <p>
        Instandsetzungen und Teilerneuerungen sind positiv zu berücksichtigen.
      </p>
      <p></p>
      <ul class="list">
        <li>Älter als 40 Jahre - 30</li>
        <li>bis 40 - 5</li>
        <li>25 bis 30 - 15</li>
        <li>bis 25 - 3</li>
        <li>10 bis 15 - 2</li>
        <li>0 bis 10 - 1</li>
      </ul>
    </div>

    <div *ngIf="text === 'auslastung'">
      <b>Überlastung</b> sollte nach Gefühl bewertet werden. Die Überlastung
      kann auf Grundlage der durchschnittlichen Belastung im Verhältnis zur
      Nennbelastung bewertet werden, wenn dementsprechende Infromationen
      vorliegen. Andernfalls können Thermografiebilder oder Spuren von
      thermischer Belastung als Hinweis verwendet werden.

      <p>
        <b>Auslastung</b> von Anlagen wird beeinflusst von betrieblichen
        Verhältnissen wie beispielsweise Einschichtbetrieb (8h/24h) oder
        Mehrschichtbetrieb (24h/24h) sowie den Anlagenzwecke wie Photovoltaik-
        oder BHKW-Einspeisung.
      </p>

      <p>
        <b>Vibration</b> ist dann zu bewerten, wenn Teile einer Anlage
        beispielsweise durch Vibrations, Stoß oder Transport bewegt werden. Die
        Montage eines Schaltschrankes auf einem beweglichen Bedienterminals oder
        auf einem Ausleger einer Maschine sind Beispiele für Montagebedingungen,
        welche vibrationsbelastung hervorrufen können.
      </p>
    </div>

    <div *ngIf="text === 'umgebungsbedingungen'"></div>

    <div *ngIf="text === 'rcd'">
      Sind RCD's, Differenzstrom- oder Fehlerspannungs-Schutzschalter vorhanden,
      muss eine weitere prüfung durch Benutzer organisiert werden.
    </div>

    <div *ngIf="text === 'zugänglich'">Zugänglich</div>

    <div *ngIf="text === 'verriegelung'">Verriegelung</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="helper = false">
      Ok
    </button>
  </div>
</clr-modal>
