import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { RiskTabResultsComponent } from '../risks-tab-results/risks-tab-results.component';
import { Evaluation, EvaluationCalculations } from 'src/app/models/Evaluation';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-review',
  templateUrl: './risks-review.component.html',
  styleUrls: ['./risks-review.component.css'],
})
export class RisksReviewComponent implements OnInit, AfterViewInit {
  cabinet$ = undefined as unknown as Observable<Cabinet | null>;
  evaluationCalculations$ = {} as EvaluationCalculations;
  template$ = new Observable<Template>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  evaluations$ = new Observable<Evaluation[]>();
  evaluation$ = undefined as unknown as Observable<Evaluation>;

  cabinet = null as Cabinet | null;
  evaluation = undefined as unknown as Evaluation;

  @ViewChild('Auswertung') tabAuswertung = {} as RiskTabResultsComponent;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.cabinet$ = this.service.loadCabinet(params['id']);
    });
    if (this.cabinet$) {
      this.cabinet$?.subscribe((cabinet) => {
        this.cabinet = cabinet;
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.cabinet$) {
      this.cabinet$?.subscribe((cabinet) => {
        this.cabinet = cabinet;
      });
    }
  }

  initAuswertung($event: any) {
    try {
      this.tabAuswertung = $event as RiskTabResultsComponent;
      if (this.evaluation) {
        this.tabAuswertung.setEvaluation(this.evaluation);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
