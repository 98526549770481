import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Telemetry } from 'src/app/models/Telemetry';

export const TelemetryActions = createActionGroup({
  source: 'Telemetry',
  events: {
    'Load Telemetries': emptyProps(),
    'Load Telemetries Success': props<{ telemetries: Telemetry[] }>(),
    'Load Telemetries Failure': props<{ error: HttpErrorResponse }>(),

    'Load Telemetry': props<{ uid: string }>(),
    'Load Telemetry Success': props<{ telemetry: Telemetry }>(),
    'Load Telemetry Failure': props<{ error: HttpErrorResponse }>(),
  },
});
