import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStations from './station.reducer';
import { Station } from 'src/app/models/Station';

const { selectAll } = fromStations.adapter.getSelectors();

export const selectStationState = createFeatureSelector<fromStations.State>(
  fromStations.stationFeatureKey,
);

export const selectStations = createSelector(selectStationState, selectAll);

export const selectStation = (uid: string) =>
  createSelector(
    selectStations,
    (stations) =>
      stations.find((station) => station.Uid === uid) || ({} as Station),
  );

export const selectStationsLoading = createSelector(
  selectStationState,
  (state) => state.loading,
);

export const selectStationsError = createSelector(
  selectStationState,
  (state) => state.error,
);

export const selectCombinedStations = createSelector(
  selectStations,
  selectStationsLoading,
  selectStationsError,
  (stations, loading, error) => ({
    stations,
    loading,
    error,
  }),
);

export const selectCombinedStation = (uid: string) =>
  createSelector(
    selectStation(uid),
    selectStationsLoading,
    selectStationsError,
    (station, loading, error) => ({
      station,
      loading,
      error,
    }),
  );
