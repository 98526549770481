import { BaseImage } from './Evaluation';

/**
 *
 * @param original the 'Cabinet'
 * @param toCompare the 'Cabinet' to caompare with.
 * @returns 'true' if the property-values are eques, otherwise 'false'
 */
export function compareCabinets(
  original: Cabinet,
  toCompare: Cabinet,
): boolean {
  let result = true;
  try {
    if (result && original._id !== toCompare._id) {
      result = false;
      console.warn(
        `compareCabinets() original._id[${original._id}] !== toCompare._id:[${toCompare._id}]`,
      );
    }
    // if (result && original.Template._id !== toCompare.Template._id) {
    //   result = false;
    //   console.warn(
    //     `compareCabinets() original.Template[${original.Template._id}] !== toCompare.Template:[${toCompare.Template._id}]`,
    //   );
    // }
    if (result && original.Uid !== toCompare.Uid) {
      result = false;
      console.warn(
        `compareCabinets() original.Uid[${original.Uid}] !== toCompare.Uid:[${toCompare.Uid}]`,
      );
    }
    // original.Anlage.forEach((originalAnlageItem) => {
    for (const originalAnlageItem of original.Anlage) {
      const found = toCompare.Anlage.find(
        (itm) => itm.ItemId == originalAnlageItem.ItemId,
      );
      if (found) {
        if (result && originalAnlageItem.Value !== found.Value) {
          console.info(
            `Anlage-Item-Id:[${originalAnlageItem.ItemId}] : original.Value[${originalAnlageItem.Value}] !== toCompare.Vaue[${found.Value}]`,
          );
          result = false;
          break;
        }
      } else {
        console.info(
          `Anlage-Item-Id:[${originalAnlageItem.ItemId}] : Item not found => not equal!`,
        );
        result = false;
        break;
      }
    }
    // original.Images?.forEach((originalImageItem) => {
    if (result && original.Images) {
      for (const originalImageItem of original.Images) {
        const found = toCompare.Images?.find(
          (itm) => itm._id == originalImageItem._id,
        );
        if (found) {
          const equal =
            originalImageItem.AsPreview == found.AsPreview &&
            originalImageItem.InternalName == found.InternalName &&
            originalImageItem.Path == found.Path &&
            originalImageItem.Name == found.Name &&
            originalImageItem.Original == found.Original &&
            originalImageItem.Extension == found.Extension &&
            originalImageItem.Encoding == found.Encoding &&
            originalImageItem.MIME == found.MIME &&
            originalImageItem.Size == found.Size &&
            originalImageItem.Thumb?.toString() == found.Thumb?.toString();
          if (result && !equal) {
            console.info(
              `Image-Id:[${originalImageItem._id}] : original !== toCompare`,
            );
            console.log(
              `AsPreview [${originalImageItem.AsPreview}] == [${
                found.AsPreview
              }]\n
              InternalName [${originalImageItem.InternalName}] == [${
                found.InternalName
              }]\n
              Path [${originalImageItem.Path}] == [${found.Path}]\n
              Name [${originalImageItem.Name}] == [${found.Name}]\n
              Original [${originalImageItem.Original}] == [${found.Original}]\n
              Extension [${originalImageItem.Extension}] == [${
                found.Extension
              }]\n
              Encoding [${originalImageItem.Encoding}] == [${found.Encoding}]\n
              MIME [${originalImageItem.MIME}] == [${found.MIME}]\n
              Size [${originalImageItem.Size}] == [${found.Size}]\n
              Thumb [${originalImageItem.Thumb?.toString()} == [${found.Thumb?.toString()}]`,
            );
            result = false;
            break;
          }
        } else {
          console.info(
            `Image-Id:[${originalImageItem._id}] : Item not found => not equal!`,
          );
          result = false;
          break;
        }
      }
    }
  } catch (err) {
    console.error('compareCabinets() ERROR:', err);
    result = false;
  }
  return result;
}

export interface Cabinet extends LowCabinet {
  _id: string;

  Anlage: {
    ItemId: string;
    Value: object;
  }[];
}

export interface CabinetImg extends BaseImage {
  AsPreview: boolean;
}

export interface LowCabinet {
  _id?: string;

  Uid: string;
  // Template?: any;

  Anlage?: {
    ItemId: string;
    Value: object;
  }[];

  Images?: CabinetImg[];
}

export interface ImageEntry {
  _id: string;
  imgFile: File;
}
