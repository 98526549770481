import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { Template } from 'src/app/models/Template';
import { Item } from 'src/app/models/Item';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { Evaluation } from 'src/app/models/Evaluation';
import { RiskTabEditTemplateComponent } from '../risks-tab-template/risks-tab-template.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-template-edition',
  templateUrl: './risks-template-edition.component.html',
  styleUrls: ['./risks-template-edition.component.css'],
})
export class RisksEditTemplateComponent implements OnInit {
  template$: Observable<Template> | undefined = undefined;
  loading$: Observable<boolean> | undefined = undefined;
  error$: Observable<HttpErrorResponse | null> | undefined = undefined;
  evaluations$: Observable<[Evaluation]> | undefined = undefined;
  evaluation$: Observable<Evaluation> | undefined = undefined;
  template: Template = {} as Template;

  @Output() itmsAnlage$ = [] as Item[];
  @Output() itmsPruefung$ = [] as Item[];
  @Output() itmsGefaehrdung$ = [] as Item[];

  @ViewChild('anlageTab') tabAnlage =
    undefined as unknown as RiskTabEditTemplateComponent;
  @ViewChild('pruefungTab') tabPruefung =
    undefined as unknown as RiskTabEditTemplateComponent;
  @ViewChild('gefaehrdungTab') tabGefaehrdung =
    undefined as unknown as RiskTabEditTemplateComponent;
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  submitAnlageState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitPruefungState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitGefaehrdungenState: ClrLoadingState = ClrLoadingState.DEFAULT;
  helper = false;
  text = '';

  onHelper(text: string) {
    this.helper = true;
    this.text = text.toLowerCase();
  }

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.template$ = this.service.loadTemplate();
      this.template$?.subscribe((tmpTemplate) => {
        this.itmsAnlage$ = tmpTemplate.Anlage;
        this.itmsPruefung$ = tmpTemplate.Pruefung;
        this.itmsGefaehrdung$ = tmpTemplate.Gefaehrdung;
        this.template = tmpTemplate;
      });
    });
  }

  refresh(riskTab: RiskTabEditTemplateComponent) {
    switch (
      (riskTab as RiskTabEditTemplateComponent).tabFor$.toLocaleLowerCase()
    ) {
      case 'anlage':
        this.itmsAnlage$ = riskTab.itmArray$;
        this.template.Anlage = this.itmsAnlage$;
        break;
      case 'pruefung':
        this.itmsPruefung$ = riskTab.itmArray$;
        this.template.Pruefung = this.itmsPruefung$;
        break;
      case 'gefaehrdung':
        this.itmsGefaehrdung$ = riskTab.itmArray$;
        this.template.Gefaehrdung = this.itmsGefaehrdung$;
        break;
    }
  }

  onSaveClick() {
    this.template.Anlage = this.itmsAnlage$;
    this.template.Pruefung = this.itmsPruefung$;
    this.template.Gefaehrdung = this.itmsGefaehrdung$;
    this.service.patchTemplate(this.template);
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initAnlage($event: any) {
    try {
      this.tabAnlage = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initPruefung($event: any) {
    try {
      this.tabPruefung = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initGefaehrdung($event: any) {
    try {
      this.tabGefaehrdung = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  // initFiles($event: any) {
  //   try {
  //     this.tabFotos = $event as RiskTabFilesComponent;
  //   } catch (err) {
  //     console.error('FEHLER', err);
  //   }
  // }
}
