import { Component, Input, OnInit } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { MqttEventService } from 'src/app/services/mqtt-event.service';
import { TelecontrolMessages } from 'src/app/shared/Telecontrol-Messages';
import { TelecontrolMessagesService } from 'src/app/shared/telecontrol-messages.service';

@Component({
  selector: 'app-telecontrols-messages-grid',
  templateUrl: './telecontrols-messages-grid.component.html',
  styleUrls: ['./telecontrols-messages-grid.component.css'],
})
export class TelecontrolsMessagesGridComponent implements OnInit {
  @Input() client = '';

  telecontrolData$: Observable<TelecontrolMessages> =
    new Observable<TelecontrolMessages>();

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private mqttService: MqttEventService,
    private telecontrolMessagesService: TelecontrolMessagesService,
  ) {}

  ngOnInit() {
    this.telecontrolData$ = this.telecontrolMessagesService.getObjectByKey(
      this.client,
    );
  }

  onUpdate() {
    this.submitBtnState = ClrLoadingState.LOADING;
    this.mqttService
      .updateMessages(this.client)
      .then((result) => {
        this.submitBtnState = ClrLoadingState.SUCCESS;
      })
      .catch((error) => {
        this.submitBtnState = ClrLoadingState.ERROR;
      });
  }
}
