import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Cabinet, CabinetImg } from 'src/app/models/Cabinet';
import { ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  DialogAddImageComponent,
  CheckObj,
} from '../dialog-add-image/dialog-add-image.component';
import { event } from '@cds/core/internal';

@Component({
  selector: 'app-risks-tab-images-cabinet',
  templateUrl: './risks-tab-images-cabinet.component.html',
  styleUrls: ['./risks-tab-images-cabinet.component.css'],
})
export class RiskTabCabinetImagesComponent implements OnInit, OnChanges {
  @Input() cabinet: Cabinet | null = null;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  filesDialog: DialogAddImageComponent | null = null;
  online = this.service.isOnline$;

  // images$: CabinetImg[] = [];

  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private service: AssessmentStoreService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      console.log('changes[cabinet] images:', this.cabinet?.Images?.length);
      // if (this.cabinet?.Images) this.images$ = this.cabinet.Images;
    }
  }

  ngOnInit() {
    this.init.emit(this);
  }

  setDialog($event: any) {
    this.filesDialog = $event as DialogAddImageComponent;
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    this.dialogAddFile = true;
    return false;
  }

  getThumbImage(image: CabinetImg) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: CabinetImg) {
    if (this.cabinet) {
      this.service
        .loadCabinetImage(this.cabinet._id, image._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${image.MIME};base64,${result?.data}`;
          this.dialogViewImg = true;
        });
    }
  }

  onSetPreviewImg(image: CabinetImg) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      const images = [] as CabinetImg[];
      this.cabinet?.Images?.map((img) => {
        if (img._id == image._id) {
          img.AsPreview = true;
        } else {
          img.AsPreview = false;
        }
        images.push(img);
      });
      const patchCabinet = {
        _id: this.cabinet._id,
        Images: images,
      } as Cabinet;
      this.service.patchCabinet(patchCabinet).subscribe((cabinet) => {
        this.cabinet = cabinet;
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }

  onUpdateFile(fileData: any) {
    console.log('onUpdateFile()', fileData);

    const fileDat = fileData as CheckObj;
    if (fileDat.file && this.cabinet) {
      this.service
        .postCabinetImage(fileDat, this.cabinet)
        .subscribe((result) => {
          this.cabinet = result as Cabinet;
          this.updated.emit(this.cabinet);
          this.ngOnInit();
          this.filesDialog?.fileSend(true, fileDat.idx);
        });
    }
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      console.log(`onDeleteFile control?._id : ${control?._id}`);
      this.service
        .deleteCabinetImage(this.cabinet._id, control?._id)
        .subscribe((C) => {
          this.cabinet = C as Cabinet;
          this.updated.emit(C as Cabinet);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }
}
