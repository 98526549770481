import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelecontrolMessages } from './Telecontrol-Messages';

@Injectable({
  providedIn: 'root',
})
export class TelecontrolMessagesService {
  private InfoSource = new BehaviorSubject<number>(0);
  info = this.InfoSource.asObservable();

  private WarnSource = new BehaviorSubject<number>(0);
  warn = this.WarnSource.asObservable();

  private AlarmSource = new BehaviorSubject<number>(0);
  alarm = this.AlarmSource.asObservable();

  private myDictionarySubject = new BehaviorSubject<{
    [key: string]: TelecontrolMessages;
  }>({});
  myDictionary$ = this.myDictionarySubject.asObservable();

  update(key: string, telecontrolMessages: TelecontrolMessages): void {
    const currentDictionary = { ...this.myDictionarySubject.value };

    currentDictionary[key] = telecontrolMessages;

    this.myDictionarySubject.next(currentDictionary);

    this.InfoSource.next(
      telecontrolMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'info',
      ).length,
    );

    this.WarnSource.next(
      telecontrolMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'warnung',
      ).length,
    );

    this.AlarmSource.next(
      telecontrolMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'alarm',
      ).length,
    );
  }

  getObjectByKey(key: string): Observable<TelecontrolMessages> {
    return this.myDictionary$.pipe(
      map((dictionary) => {
        const telecontrolMessages = dictionary[key] || { Signals: [] };
        if (telecontrolMessages.Signals) {
          telecontrolMessages.Signals.sort(
            (a, b) =>
              new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime(),
          );
        }
        return telecontrolMessages;
      }),
    );
  }
}
