import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTelemetry from './telemetry.reducer';
import { Telemetry } from 'src/app/models/Telemetry';

const { selectAll } = fromTelemetry.adapter.getSelectors();

export const selectTelemetryState = createFeatureSelector<fromTelemetry.State>(
  fromTelemetry.telemetryFeatureKey,
);

export const selectTelemetries = createSelector(
  selectTelemetryState,
  selectAll,
);

export const selectTelemetry = (uid: string) =>
  createSelector(
    selectTelemetries,
    (telemetries) =>
      telemetries.find((telemetry) => telemetry.Uid === uid) ||
      ({} as Telemetry),
  );

export const selectTelemetriesLoading = createSelector(
  selectTelemetryState,
  (state) => state.loading,
);

export const selectTelemetriesError = createSelector(
  selectTelemetryState,
  (state) => state.error,
);

export const selectCombinedTelemetries = createSelector(
  selectTelemetries,
  selectTelemetriesLoading,
  selectTelemetriesError,
  (stations, loading, error) => ({
    stations,
    loading,
    error,
  }),
);

export const selectCombinedTelemetry = (uid: string) =>
  createSelector(
    selectTelemetry(uid),
    selectTelemetriesLoading,
    selectTelemetriesError,
    (station, loading, error) => ({
      station,
      loading,
      error,
    }),
  );
