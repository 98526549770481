import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { P404Component } from './pages/p404/p404.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { RequestPwdComponent } from './pages/request-pwd/request-pwd.component';
import { RenewPwdComponent } from './pages/renew-pwd/renew-pwd.component';

import { HomeComponent } from './views/+home/home.component';
import { CanNavigateToManagerGuard } from './services/can-navigate-to-manager.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./views/+home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'factories',
        loadChildren: () =>
          import('./views/factories/factories.module').then(
            (m) => m.FactoriesModule,
          ),
      },
      {
        path: 'contracts',
        loadChildren: () =>
          import('./views/contracts/contracts.module').then(
            (m) => m.ContractsModule,
          ),
      },
      {
        path: 'stations',
        loadChildren: () =>
          import('./views/stations/stations.module').then(
            (m) => m.StationsModule,
          ),
      },
      {
        path: 'telecontrols',
        loadChildren: () =>
          import('./views/telecontrols/telecontrols.module').then(
            (m) => m.TelecontrolsModule,
          ),
      },
      {
        path: 'telemetries',
        loadChildren: () =>
          import('./views/telemetries/telemetries.module').then(
            (m) => m.TelemetriesModule,
          ),
      },
      {
        path: 'risks',
        loadChildren: () =>
          import('./views/risks/risks.module').then((m) => m.RisksModule),
      },
      {
        path: 'archives',
        loadChildren: () =>
          import('./views/archives/archives.module').then(
            (m) => m.ArchivesModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule),
        canActivate: [CanNavigateToManagerGuard],
      },
      {
        path: 'info',
        loadChildren: () =>
          import('./views/info/info.module').then((m) => m.InfoModule),
      },
    ],
  },
  { path: '', component: HomeComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'signin', component: SignInComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'request', component: RequestPwdComponent },
  { path: 'renew', component: RenewPwdComponent },
  { path: 'request', component: RequestPwdComponent },
  { path: 'renew', component: RenewPwdComponent },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
