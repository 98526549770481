import { Component } from '@angular/core';
import { selectTelecontrols } from '../+store/telecontrol.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-telecontrols-messages',
  templateUrl: './telecontrols-messages.component.html',
  styleUrls: ['./telecontrols-messages.component.css'],
})
export class TelecontrolsMessagesComponent {
  combinedTelecontrols$ = this.store.select(selectTelecontrols);

  constructor(private store: Store) {}
}
