import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface CheckObj {
  file: File;
  idx: number;
  filescount: number;
}

interface PrivCheckObj {
  idx: number;
  uploaded: boolean;
  response: boolean;
}

@Component({
  selector: 'app-dialog-add-image',
  templateUrl: './dialog-add-image.component.html',
  styleUrls: ['./dialog-add-image.component.css'],
})
export class DialogAddImageComponent implements OnInit {
  @Output() isDialogOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<CheckObj> = new EventEmitter<CheckObj>();
  @Output() initializied: EventEmitter<DialogAddImageComponent> =
    new EventEmitter<DialogAddImageComponent>();

  ngOnInit(): void {
    this.initializied.emit(this);
  }

  files: File[] = [];
  checkList: PrivCheckObj[] = [];

  fileCount = 0;
  step = 0;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  private formBuilder = inject(FormBuilder);

  formMobile = this.formBuilder.group({
    Image: this.formBuilder.group({}),
  });

  onFilesSelected(event: any) {
    this.files = event.target.files;
    console.log('onFilesSelected() files', this.files?.length);
    this.fileCount = this.files.length;
    this.checkList = [];
    for (let i = 0; i < this.fileCount; i += 1) {
      this.checkList.push({
        idx: i,
        uploaded: false,
        response: false,
      } as PrivCheckObj);
    }
  }

  public fileSend(succes: boolean, idx: number): void {
    try {
      const checker = this.checkList.find((c) => c.idx == idx);
      if (checker) {
        checker.uploaded = succes;
        checker.response = true;
      }
      const done = this.checkList.filter((c) => c.response).length;
      this.uploadProgress = done * this.step;
      if (this.checkList.length == done) {
        this.closeDialog();
        this.uploadProgress = 0;
      }
      console.log(`fileSend(${idx})    ${done} / ${this.checkList.length}`);
    } catch (err) {
      console.error('DIALOG `fileSend()` Error:', err);
    }
  }

  onSubmitFile() {
    if (this.files) {
      this.fileCount = this.files.length;
      this.step = 100 / this.fileCount;
      for (let i = 0; i < this.fileCount; i += 1) {
        const resFile = {
          file: this.files[i],
          idx: i,
          filescount: this.fileCount,
        };
        this.updated.emit(resFile);
      }
    }
  }

  closeDialog() {
    this.isDialogOpen.emit(false);
  }
}
