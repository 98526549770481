import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

@Component({
  selector: 'app-users-list-users',
  templateUrl: './users-list-users.component.html',
  styleUrls: ['./users-list-users.component.css'],
})
export class UsersListUsersComponent {
  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  constructor(private store: Store) {
    this.store.dispatch(UserActions.loadUsersAll());
  }
}
