import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelemetryPresets } from './Telemetry-Presets';

@Injectable({
  providedIn: 'root',
})
export class TelemetryPresetsService {
  private myDictionarySubject = new BehaviorSubject<{
    [key: string]: TelemetryPresets;
  }>({});
  myDictionary$ = this.myDictionarySubject.asObservable();

  update(
    key: string,
    newSignal: {
      Id: number;
      Class: string;
      Timestamp: string;
      Message: string;
      System: string;
      Location: string;
      Device: string;
    },
  ): void {
    const currentDictionary = { ...this.myDictionarySubject.value };

    // Wenn der Schlüssel (key) bereits vorhanden ist.
    if (currentDictionary[key]) {
      const existingSignals = currentDictionary[key].Signals || [];

      const existingIndex = existingSignals.findIndex(
        (existingSignal) => existingSignal.Id === newSignal.Id,
      );

      // Wenn das Signal mit derselben "Id" bereits vorhanden ist, überschreibe es.
      if (existingIndex !== -1) {
        existingSignals[existingIndex] = newSignal;
      } else {
        // Wenn das Signal mit derselben "Id" nicht vorhanden ist, füge es hinzu.
        existingSignals.push(newSignal);
      }

      currentDictionary[key].Signals = existingSignals;
    } else {
      // Wenn der Schlüssel nicht vorhanden ist, erstelle ein neues Objekt mit den Werten.
      currentDictionary[key] = {
        // Füge hier die weiteren Eigenschaften hinzu
        Timestamp: newSignal.Timestamp,
        Signals: [newSignal],
      };
    }

    this.myDictionarySubject.next(currentDictionary);
  }

  getObjectByKey(key: string): Observable<TelemetryPresets> {
    return this.myDictionary$.pipe(
      map((dictionary) => {
        const telemetryPresets = dictionary[key];
        // Sortiere die Signale nach dem Timestamp absteigend
        if (telemetryPresets) {
          telemetryPresets.Signals.sort(
            (a, b) =>
              new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime(),
          );
        }
        return telemetryPresets;
      }),
    );
  }
}
