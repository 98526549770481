<br />

<button
  class="btn btn-outline"
  [disabled]="disabled"
  (click)="onAddFileClick()"
>
  <cds-icon shape="plus-circle"></cds-icon> hinzufügen
</button>

<div>
  <table class="table">
    <thead>
      <tr>
        <th class="left">Name</th>
        <th>Vorschau</th>
        <th>Optionen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let img of evaluation?.Images; let i = index">
        <td class="left">
          <a [routerLink]="[]" role="button" (click)="showImage(img)">
            {{ img.Name }}
          </a>
        </td>
        <td>
          <img
            [src]="getThumbImage(img)"
            width="40px"
            alt="Vorschau"
            role="button"
            (click)="showImage(img)"
          />
        </td>
        <td>
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="löschen"
            [disabled]="disabled"
            (click)="onDeleteFile(img)"
          >
            <cds-icon shape="trash" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- dialogs -->

<app-dialog-add-image
  #imgDialog
  *ngIf="dialogAddFile"
  (isDialogOpen)="onDialogAddFileClosing()"
  (updated)="onUpdateFile($event)"
  (initializied)="setDialog($event)"
></app-dialog-add-image>

<clr-modal [(clrModalOpen)]="dialogViewImg" clrModalSize="xl">
  <div class="modal-body">
    <img [src]="imgViewSrc" alt="ANZEIGE" style="align-self: center" />
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="dialogViewImg = false"
    >
      Ok
    </button>
  </div>
</clr-modal>
