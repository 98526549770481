import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedStations } from '../+store/station.selectors';
import { StationActions } from '../+store/station.actions';

@Component({
  selector: 'app-stations-list',
  templateUrl: './stations-list.component.html',
  styleUrls: ['./stations-list.component.css'],
})
export class StationsListComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedStations$ = this.store.select(selectCombinedStations);

  constructor(private store: Store) {}

  ngOnInit(): void {
    // this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(StationActions.loadStations());
      }
    });
  }
}
