<!-- clr-required-mark -->
<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="true"
  (clrModalOpenChange)="openChange($event)"
>
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> Schaltanlage
  </h3>
  <div class="modal-body">
    <!-- <ng-container *ngIf="template"></ng-container> -->
    <form
      clrForm
      [formGroup]="formDynamisch"
      (ngSubmit)="onSubmitAnlage()"
      id="dynamicForm"
    >
      <!-- <ng-container *ngIf="template$ | async as template"> -->
      <!-- <ng-container *ngIf="template"> -->
      <!-- <clr-input-container *ngFor="let anlageItem of getTemplateAnlageItems()"> -->
      <!-- <clr-input-container *ngFor="let itm of templateItems">
        <label class="clr-col-12 clr-col-md-4" for="dynamicForm">{{
          itm.Title
        }}</label>
        <input
          #input
          class="clr-col-12 clr-col-md-8"
          clrInput
          [id]="itm._id!"
          [formControlName]="itm._id!"
          (keypress)="preventEnter($event, itm)"
        />
        <!-- //"template._id!" -->
      <!-- [formControlName]="'anlage' + template.Index" -->
      <ng-container *ngFor="let itm of templateItems">
        <ng-container *ngIf="itm.ItemType === 'Date'">
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="inputContainer">
              {{ itm.Title }}
            </label>
            <input
              clrInput
              type="date"
              [id]="itm._id!"
              [formControlName]="itm._id!"
              (keypress)="preventEnter($event, itm)"
              style="width: 100%"
              class="clr-col-12 clr-col-md-8"
            />
          </clr-input-container>
        </ng-container>
        <ng-container *ngIf="itm.ItemType === 'Strings'">
          <clr-select-container>
            <label class="clr-col-12 clr-col-md-4" for="inputContainer">
              {{ itm.Title }}
            </label>
            <select
              class="clr-col-12 clr-col-md-8"
              clrSelect
              style="width: 100%"
              [formControlName]="itm._id!"
              [id]="itm._id!"
              (keypress)="preventEnter($event, itm)"
            >
              <option
                style="width: 100%"
                *ngFor="let ctlOption of itm.Selection"
                (keypress)="preventEnter($event, itm)"
                [ngValue]="ctlOption"
              >
                {{ ctlOption }}
              </option>
            </select>
          </clr-select-container>
        </ng-container>
        <ng-container *ngIf="itm.ItemType === 'String'">
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="inputContainer">
              {{ itm.Title }}
            </label>
            <input
              style="width: 100%"
              class="clr-col-12 clr-col-md-8"
              clrInput
              [id]="itm._id!"
              [formControlName]="itm._id!"
              (keypress)="preventEnter($event, itm)"
            />
          </clr-input-container>
        </ng-container>
        <ng-container *ngIf="itm.ItemType === 'Number'">
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="inputContainer">
              {{ itm.Title }}
            </label>
            <input
              style="width: 100%"
              type="number"
              class="clr-col-12 clr-col-md-8"
              clrInput
              [id]="itm._id!"
              [formControlName]="itm._id!"
              (keypress)="preventEnter($event, itm)"
            />
          </clr-input-container>
        </ng-container>
      </ng-container>

      <!-- </clr-input-container> -->
      <!-- </ng-container> -->
      <p></p>
      <button #submit type="submit" class="btn btn-primary">speichern</button>
    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline" (click)="onCancel()">
      Cancel
    </button> -->
    <!-- <button type="button" class="btn btn-primary" (click)="onOkay()">Ok</button> -->
  </div>
</clr-modal>
