import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Telecontrol } from 'src/app/models/Telecontrol';

export const TelecontrolActions = createActionGroup({
  source: 'Telecontrol',
  events: {
    'Load Telecontrols': emptyProps(),
    'Load Telecontrols Success': props<{ telecontrols: Telecontrol[] }>(),
    'Load Telecontrols Failure': props<{ error: HttpErrorResponse }>(),

    'Load Telecontrol': props<{ uid: string }>(),
    'Load Telecontrol Success': props<{ telecontrol: Telecontrol }>(),
    'Load Telecontrol Failure': props<{ error: HttpErrorResponse }>(),
  },
});
