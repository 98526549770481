import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { TelecontrolActions } from './telecontrol.actions';
import { TelecontrolStoreService } from 'src/app/services/telecontrol-store.service';

@Injectable()
export class TelecontrolEffects {
  private service = inject(TelecontrolStoreService);

  loadTelemetries$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelecontrolActions.loadTelecontrols),
      switchMap(() =>
        this.service.getAll().pipe(
          map((telecontrols) =>
            TelecontrolActions.loadTelecontrolsSuccess({
              telecontrols: telecontrols,
            }),
          ),
          catchError((error) =>
            of(TelecontrolActions.loadTelecontrolsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadTelecontrol$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelecontrolActions.loadTelecontrol),
      mergeMap((action) =>
        this.service.getSingle(action.uid).pipe(
          map((telecontrol) =>
            TelecontrolActions.loadTelecontrolSuccess({
              telecontrol: telecontrol,
            }),
          ),
          catchError((error) =>
            of(TelecontrolActions.loadTelecontrolFailure({ error })),
          ),
        ),
      ),
    );
  });
}
