import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedFactories } from '../+store/factory.selectors';
import { FactoryActions } from '../+store/factory.actions';

@Component({
  selector: 'app-factories-list',
  templateUrl: './factories-list.component.html',
  styleUrls: ['./factories-list.component.css'],
})
export class FactoriesListComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedFactories$ = this.store.select(selectCombinedFactories);

  constructor(private store: Store) {}

  ngOnInit(): void {
    // this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(FactoryActions.loadFactories());
      }
    });
  }

  // this.factories$ = this.store.pipe(select(selectFactories));
  // this.loading$ = this.store.pipe(select(selectFactoriesLoading));
  // this.error$ = this.store.pipe(select(selectFactoriesError));

  // ngOnDestroy(): void {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }

  // loadFactories(): void {
  //   this.store.dispatch(FactoryActions.loadPaging({ page: this.page, limit: this.limit }));
  //
  //   // Überwachung von Änderungen in den Factory-Daten
  //   this.subscription = this.store.pipe(select(selectFactories))
  //     .pipe(
  //       tap(factories => {
  //         // Logik, um zu entscheiden, ob weitere Seiten benötigt werden
  //         if (factories.length < this.limit) {
  //           // Alle Daten geladen
  //           this.subscription.unsubscribe();
  //         } else {
  //           // Lade die nächste Seite
  //           this.page++;
  //         }
  //       })
  //     )
  //     .subscribe();
  // }
}
