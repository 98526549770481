import {
  Component,
  Input,
  OnInit,
  inject,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Evaluation, EvaluationCalculations } from 'src/app/models/Evaluation';
import { formatDate } from '@angular/common';
import { ClrLoadingState } from '@clr/angular';
import { Item } from 'src/app/models/Item';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Template } from 'src/app/models/Template';

@Component({
  selector: 'app-risks-tab-evaluation',
  templateUrl: './risks-tab-evaluation.component.html',
  styleUrls: ['./risks-tab-evaluation.component.css'],
})
export class RiskTabEvaluationComponent implements OnInit, OnChanges {
  @Input() evaluation = null as unknown as Evaluation;
  tempAuswahlElements = [] as string[];
  @Input() tabFor$ = '';
  @Input() disabled = false;
  private template: Template | undefined = undefined;
  private templateItemArray$ = [] as Item[];
  evaluationCalculations$ = null as unknown as EvaluationCalculations;

  @Output() updated = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() init = new EventEmitter();
  @Output() updatedEvaluation = new EventEmitter();

  title = '';
  message = '';
  showAuswahl = false;
  inputsOk = true;

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onHelper = false;

  constructor(private service: AssessmentStoreService) {
    this.init.emit(this);
    service.loadTemplate().subscribe((t) => (this.template = t));
  }

  getItems(): Item[] {
    const sortItms = [] as Item[];
    if (this.templateItemArray$ && this.templateItemArray$?.length > 0) {
      this.templateItemArray$.forEach((i) => sortItms.push(i));
      const sorted = sortItms.sort((a, b) => a.ItemIndex - b.ItemIndex);
      return sorted ? sorted : ([] as Item[]);
    }
    return [] as Item[];
  }

  ngOnInit() {
    this.init.emit(this);
  }

  setItemsToTab() {
    if (!this.template) {
      this.service.loadTemplate().subscribe((t) => {
        if (t) {
          this.template = t;
          this.itemsToTab(t);
        }
      });
    } else {
      this.itemsToTab(this.template);
    }
  }

  itemsToTab(template: Template) {
    console.log('itemsToTab(Template)');
    this.template = template;
    if (this.template) {
      if (this.evaluation && this.template.Anlage) {
        this.calulateFor(this.evaluation);
        switch (this.tabFor$.toLowerCase()) {
          case 'pruefung':
            this.templateItemArray$ = this.template.Pruefung;
            break;
          case 'gefaehrdung':
            this.templateItemArray$ = this.template.Gefaehrdung;
            break;
        }
        this.templateItemArray$.forEach((templateItem) => {
          if (!this.formDynamisch.get<string>(templateItem._id)) {
            const ctl = this.formBuilder?.control(templateItem.Title);
            let eItem = undefined as unknown as
              | {
                  ItemId: string;
                  Value: object;
                }
              | undefined;
            switch (this.tabFor$.toLowerCase()) {
              case 'pruefung':
                if (this.evaluation?.Pruefung) {
                  eItem = this.evaluation.Pruefung.find(
                    (e) =>
                      e.ItemId.trim().toLowerCase() ==
                      templateItem._id.trim().toLowerCase(),
                  );
                }
                break;
              case 'gefaehrdung':
                if (this.evaluation?.Gefaehrdung) {
                  eItem = this.evaluation.Gefaehrdung.find(
                    (e) =>
                      e.ItemId.trim().toLowerCase() ==
                      templateItem._id.trim().toLowerCase(),
                  );
                }
                break;
            }
            if (eItem && templateItem.Fixed && templateItem.Fixed === 3) {
              const value =
                this.evaluationCalculations$.getCalculationFor(templateItem);
              ctl.setValue(
                value?.value ? (value.value as unknown as string) : '',
              );
            } else if (eItem) {
              ctl.setValue(eItem.Value as unknown as string);
            }
            if (this.disabled) {
              ctl.disable();
            } else {
              ctl.enable();
            }
            this.formDynamisch?.addControl(templateItem._id!, ctl);
          }
        });
      }
    }
    if (!this.templateItemArray$) {
      console.warn('refreshView() templateItemArray$', this.templateItemArray$);
      return;
    }
    switch (this.tabFor$.toLowerCase()) {
      case 'pruefung':
        this.evaluation?.Pruefung?.map((e) => this.refreshControls(e));
        break;
      case 'gefaehrdung':
        this.evaluation?.Gefaehrdung?.map((e) => this.refreshControls(e));
        break;
    }
  }

  refreshControls(evaluationItem: {
    ItemId: string;
    Value: object | undefined | null;
  }) {
    const formControl = this.formDynamisch?.get<string>(
      evaluationItem.ItemId,
    ) as FormControl;
    if (formControl == null || formControl == undefined) {
      console.log(`FormControl [${evaluationItem.ItemId}] is undefined`);
      return;
    }
    if (
      evaluationItem.Value !== undefined &&
      evaluationItem.Value !== null &&
      evaluationItem.ItemId
    ) {
      try {
        const tmpItem = this.templateItemArray$.find(
          (tmpItm) => tmpItm._id == evaluationItem.ItemId,
        );
        if (tmpItem) {
          if (tmpItem && tmpItem.Fixed === 3) {
            const value =
              this.evaluationCalculations$.getCalculationFor(tmpItem);
            formControl.patchValue(value?.value);
          } else if (tmpItem && tmpItem.ItemType == 'Date') {
            try {
              const date = new Date(evaluationItem.Value as unknown as string);
              const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
              tmpItem.Value = val;
              formControl.setValue(val);
            } catch (err) {
              console.log(err);
              this.inputsOk = false;
            }
          } else if (tmpItem && tmpItem.ItemType == 'Strings') {
            const value = evaluationItem.Value as unknown as string;
            if (
              tmpItem.Selection &&
              tmpItem.Selection.length > 0 &&
              tmpItem.Selection.includes(value)
            ) {
              tmpItem.Value = value;
              formControl.setValue(value);
            } else if (tmpItem.Selection) {
              console.warn(
                `refreshView() value[${evaluationItem.Value}] not found set to [${tmpItem.Selection[0]}]:`,
              );
              formControl.patchValue(tmpItem.Selection[0]);
            }
          } else {
            tmpItem.Value = evaluationItem.Value;
            formControl.patchValue(evaluationItem.Value);
          }
          // console.info(
          //   `refreshView() patchValue(${evaluationItem.Value})  item:`,
          //   tmpItem.Value,
          // );
          if (this.disabled) {
            formControl.disable();
          } else {
            formControl.enable();
          }
        } else {
          console.error(
            `ArrayItem for [${evaluationItem.ItemId}] not found.`,
            this.templateItemArray$,
          );
        }
      } catch (err) {
        console.log(err);
        return;
      }
    } else if (
      evaluationItem.Value == undefined ||
      evaluationItem.Value == null
    ) {
      try {
        const tmpItem = this.templateItemArray$.find(
          (itm) => itm._id == evaluationItem.ItemId,
        );
        if (tmpItem) {
          tmpItem.Value = null;
          formControl.setValue(null);
          if (this.disabled) {
            formControl.disable();
          } else {
            formControl.enable();
          }
          this.inputsOk = false;
        } else {
          console.error(`ArrayItem for [${evaluationItem.ItemId}] not found.`);
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }
    formControl.updateValueAndValidity();
  }

  ngOnChanges(changes: any): void {
    console.log('ngOnChanges()', changes);
    try {
      if (changes['templateItemArray$']) {
        if (this.templateItemArray$ && this.templateItemArray$?.length > 0) {
          this.templateItemArray$?.map((templateItem) => {
            try {
              if (!this.formDynamisch.get<string>(templateItem._id)) {
                this.formDynamisch?.addControl(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  templateItem._id!,
                  this.formBuilder?.control(templateItem.Title),
                );
                console.log(
                  `Control [${templateItem.Title}]hinzugefuegt :`,
                  templateItem._id,
                );
              }
            } catch (err) {
              console.error('FEHLER : ', err);
            }
          });
          if (this.evaluation) {
            this.setItemsToTab();
          } else {
            console.log('ngOnChanges([templateItemArray]$)', this.evaluation);
          }
        }
      }
      if (changes['evaluation']) {
        if (this.evaluation) {
          this.setItemsToTab();
        } else {
          console.log('ngOnChanges([evaluation])', this.evaluation);
        }
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }

  getStyle(itm: Item): string {
    const templateItem = itm;
    const formControl = this.formDynamisch.get<string>(itm._id) as FormControl;
    if (formControl !== undefined && formControl !== null) {
      const Value = formControl.getRawValue();
      if (templateItem?.Fixed && templateItem?.Fixed === 3) {
        return '';
      }
      if (!Value || Value == null) return 'color: red';
      if (templateItem?.Fixed && templateItem?.Fixed > 0) {
        switch (templateItem.ItemType) {
          case 'Boolean':
            if (Value && !(typeof Value == typeof Boolean)) {
              return 'color: red';
            } else if (Value) {
              return 'color: red';
            }
            break;
          case 'Date':
            try {
              const date = new Date(Value as unknown as string);
              const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
            } catch (err) {
              return 'color: red';
            }
            break;
          case 'Number':
            if (isNaN(Value as number)) {
              return 'color: red';
            }
            break;
          case 'String':
            if ((Value as string).length == 0) return 'color: red';
            break;
          case 'Strings':
            try {
              if (!templateItem.Selection?.includes(Value as string)) {
                return 'color: red';
              }
            } catch (err) {
              return 'color: red';
            }
            break;
        }
      }
    }
    return '';
  }

  inputChanged() {
    const tab = this.formDynamisch.getRawValue();
    const arrayItem = Object.entries(tab).map(([Title, Value], index) => {
      const item = this.templateItemArray$.find((x) => x.ItemIndex == index);
      if (item && item.Fixed === 3) {
        const value =
          this.evaluationCalculations$.getCalculationFor(item)?.value;
        item.Value = value;
        return {
          ItemId: item?._id,
          Value: value,
        } as unknown as { ItemId: string; Value: object };
      }
      if (item) item.Value = Value;
      return {
        ItemId: item?._id,
        Value: Value,
      } as { ItemId: string; Value: object };
    });
    switch (this.tabFor$.toLowerCase()) {
      case 'pruefung':
        this.evaluation.Pruefung = arrayItem;
        break;
      case 'gefaehrdung':
        this.evaluation.Gefaehrdung = arrayItem;
        break;
    }
    this.checkValues();
    this.calulateFor(this.evaluation);
    if (this.inputsOk) {
      this.changed.emit(this.evaluation);
    }
  }

  calulateFor(evaluation: Evaluation) {
    if (this.evaluationCalculations$?.Calculations) {
      this.evaluationCalculations$.updateCalculation(evaluation);
    } else {
      this.evaluationCalculations$ = new EvaluationCalculations(
        evaluation,
        this.template!,
      );
    }
  }

  getVisibilityWarnings(): string {
    if (this.inputsOk) {
      return 'hidden';
    }
    return 'visible';
  }

  checkValues(): void {
    let result = true;
    const tab = this.formDynamisch.getRawValue();
    Object.entries(tab).map(([ItemId, Value], index) => {
      const templateItem = this.templateItemArray$.find(
        (x) => x.ItemIndex == index,
      );
      if (templateItem?.Fixed && templateItem?.Fixed === 3 && result) {
        result = true;
        return;
      } else if (templateItem?.Fixed && templateItem?.Fixed > 0 && result) {
        if (!Value || Value == null) result = false;
        switch (templateItem.ItemType) {
          case 'Boolean':
            if ((Value && !(typeof Value == typeof Boolean)) || !Value) {
              result = false;
              return;
            }
            break;
          case 'Date':
            try {
              const date = new Date(Value as unknown as string);
              const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
              Value = val;
            } catch (err) {
              console.error('KEIN DATUM', err);
              result = false;
              return;
            }
            break;
          case 'Number':
            if (isNaN(Value as number)) {
              console.error('KEINE ZAHL');
              result = false;
              return;
            }
            break;
          case 'String':
            break;
          case 'Strings':
            try {
              if (!templateItem.Selection?.includes(Value as string)) {
                result = false;
                return;
              }
            } catch (err) {
              console.error('Auswahl hat Fehler verursacht', err);
              result = false;
              return;
            }
            break;
        }
      }
    });
    this.inputsOk = result;
    if (!result) {
      return;
    }
  }

  onSubmitTab(event: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    let entriesOk = true;
    const tab = this.formDynamisch.getRawValue();
    Object.entries(tab).map(([ItemId, Value], index) => {
      const templateItem = this.templateItemArray$.find(
        (x) => x.ItemIndex == index,
      );
      if (templateItem?.Fixed && templateItem?.Fixed > 0 && entriesOk) {
        switch (templateItem.ItemType) {
          case 'Boolean':
            if ((Value && !(typeof Value == typeof Boolean)) || !Value) {
              entriesOk = false;
              // return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
          case 'Date':
            try {
              const date = new Date(Value as unknown as string);
              const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
              Value = val;
            } catch (err) {
              console.error('KEIN DATUM', err);
              entriesOk = false;
              // return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
          case 'Number':
            if (isNaN(Value as number)) {
              console.error('KEINE ZAHL');
              entriesOk = false;
              // return undefined as unknown as {
              //   ItemId: string;
              //   Value: object;
              // };
            }
            break;
          case 'String':
            break;
          case 'Strings':
            try {
              if (!templateItem.Selection?.includes(Value as string)) {
                entriesOk = false;
              }
            } catch (err) {
              console.error('Auswahl hat Fehler verursacht', err);
              entriesOk = false;
              // return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
        }
      }
      return {
        ItemId: templateItem?._id,
        Value: Value,
      } as { ItemId: string; Value: object };
    });
    if (!entriesOk) {
      console.error('onSubmitTab(event: any)  ==> ERROR');
      this.submitTabState = ClrLoadingState.SUCCESS;
      return;
    }
    if (this.evaluation && this.evaluation._id !== null) {
      this.service.patchEvaluation(this.evaluation).subscribe((evaluation) => {
        if (evaluation) {
          this.evaluation = evaluation;
          this.updated.emit(evaluation);
        }
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    } else {
      console.error('ERROR  ==>  this.evaluation:', this.evaluation);
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }

  deleteAuswahlByIndex(event: any, index: number) {
    this.tempAuswahlElements.splice(index, 1);
    event.preventDefault();
    return false;
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    // event.preventDefault();
    return false;
  }
}
