import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { StationStoreService } from 'src/app/services/station-store.service';
import { StationActions } from './station.actions';

@Injectable()
export class StationEffects {
  private service = inject(StationStoreService);

  loadStations$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(StationActions.loadStations),
      mergeMap(() =>
        this.service.getAll().pipe(
          map((stations) =>
            StationActions.loadStationsSuccess({ stations: stations }),
          ),
          catchError((error) =>
            of(StationActions.loadStationsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadStation$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(StationActions.loadStation),
      mergeMap((action) =>
        this.service.getSingle(action.uid).pipe(
          map((station) =>
            StationActions.loadStationSuccess({ station: station }),
          ),
          catchError((error) =>
            of(StationActions.loadStationFailure({ error })),
          ),
        ),
      ),
    );
  });
}
