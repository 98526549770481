import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { User } from 'src/app/models/User';
import {
  selectUsersError,
  selectUpdateUserSuccess,
  selectUser,
} from '../+store/user.selectors';
import { Store } from '@ngrx/store';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Role } from 'src/app/models/Role';
import { selectProfil } from '../+profil/profil.selectors';
import { UserActions } from '../+store/user.actions';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users-form-item-edit',
  templateUrl: './users-form-item-edit.component.html',
  styleUrls: ['./users-form-item-edit.component.css'],
})
export class UsersFormItemEditComponent implements OnInit {
  @Input() selected = {} as User;
  @Output() edited = new EventEmitter<boolean>();

  // user = {} as User;

  profil$ = this.store.select(selectProfil);
  error$ = this.store.select(selectUsersError);
  success$ = this.store.select(selectUpdateUserSuccess);

  formBuilder = inject(FormBuilder);

  formEdit = this.formBuilder.group({
    Id: new FormControl('', { nonNullable: true }),

    Firstname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    Lastname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    Roles: new FormControl([] as Role[], { nonNullable: false }),

    IsBlocked: new FormControl(false, { nonNullable: true }),
  });

  get firstname() {
    return this.formEdit.get('Firstname');
  }
  get lastname() {
    return this.formEdit.get('Lastname');
  }

  roles = [] as Role[];

  submitBtnUser: ClrLoadingState = ClrLoadingState.DEFAULT;

  selected$ = new Observable<User>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selected$ = this.store.select(selectUser(this.selected.Username));

    this.profil$.subscribe((profil) => {
      if (profil) {
        this.roles = [];
        profil?.Roles?.map((r) => {
          this.roles.push(r);
        });
      }
    });

    this.selected$.subscribe((selected) => {
      // this.user = selected;

      const roles = this.roles
        .filter(
          (role) =>
            selected.Roles?.some(
              (selectedRole) => selectedRole.Symbol === role.Symbol,
            ),
        )
        .sort((a, b) => a.Name.localeCompare(b.Name));

      this.formEdit.get('Id')?.setValue(selected._id);
      this.formEdit.get('IsBlocked')?.setValue(selected.SignIn.IsBlocked);
      this.formEdit.get('Roles')?.setValue(roles);
      this.formEdit.get('Firstname')?.setValue(selected.Firstname);
      this.formEdit.get('Lastname')?.setValue(selected.Lastname);
    });
  }

  onLock(user: User) {
    // this.store.dispatch(UserActions.lockUser({ id: user._id, status: true }));

    const data = this.formEdit.getRawValue();
    const blocked = true;
    const id = data.Id;

    this.store.dispatch(
      UserActions.updateUser({
        id: id,
        blocked: blocked,
      }),
    );
  }

  onUnLock(user: User) {
    // this.store.dispatch(UserActions.lockUser({ id: user._id, status: false }));

    const data = this.formEdit.getRawValue();
    const blocked = false;
    const id = data.Id;

    this.store.dispatch(
      UserActions.updateUser({
        id: id,
        blocked: blocked,
      }),
    );
  }

  onEditedCancel() {
    this.edited.emit(false);
  }

  onUpdateProfil() {
    this.submitBtnUser = ClrLoadingState.LOADING;

    const data = this.formEdit.getRawValue();
    const id = data.Id;
    const roles = data.Roles || ([] as Role[]);
    const firstname = data.Firstname;
    const lastname = data.Lastname;

    this.store.dispatch(
      UserActions.updateUser({
        id: id,
        firstname: firstname,
        lastname: lastname,
        roles: roles.map((role) => role._id),
      }),
    );

    this.success$.subscribe((result) => {
      if (result) {
        this.submitBtnUser = ClrLoadingState.SUCCESS;
      } else {
        this.submitBtnUser = ClrLoadingState.ERROR;
      }
      this.edited.emit(false);
    });
  }
}
