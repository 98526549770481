import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractsListComponent } from '../contracts/contracts-list/contracts-list.component';
import { ContractsDetailComponent } from './contracts-detail/contracts-detail.component';

const routes: Routes = [
  { path: '', component: ContractsListComponent },
  { path: ':uid', component: ContractsDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractsRoutingModule {}
