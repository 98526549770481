import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ContractActions } from './contract.actions';
import { ContractStoreService } from 'src/app/services/contract-store.service';

@Injectable()
export class ContractEffects {
  private service = inject(ContractStoreService);

  loadContracts$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ContractActions.loadContracts),
      mergeMap(() =>
        this.service.getAll().pipe(
          map((contracts) =>
            ContractActions.loadContractsSuccess({ contracts: contracts }),
          ),
          catchError((error) =>
            of(ContractActions.loadContractsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadContract$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ContractActions.loadContract),
      mergeMap((action) =>
        this.service.getSingle(action.uid).pipe(
          map((contract) =>
            ContractActions.loadContractSuccess({ contract: contract }),
          ),
          catchError((error) =>
            of(ContractActions.loadContractFailure({ error })),
          ),
        ),
      ),
    );
  });
}
