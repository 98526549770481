import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { StatusService } from 'src/app/services/status.service';
import { CustomValidators } from 'src/app/utils/validation';

interface Token extends JwtPayload {
  username: string;
  firstname: string;
  lastname: string;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  background = this._background[0];

  multifactor = false;

  errorSignup = false;
  signupErrorResponse = new HttpErrorResponse({});

  userEmails = this.formBuilder.group(
    {
      url: '',
      token: '',
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      username: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      datenschutz: [
        { value: false, disabled: true },
        [Validators.requiredTrue],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          CustomValidators.complex(),
        ],
      ],
      confirm: ['', [Validators.required]],
    },
    {
      validators: [
        CustomValidators.match('password', 'confirm'),
        CustomValidators.unmatch('username', 'password'),
      ],
    },
  );

  signupOberserver = {
    next: (user: any) => this.onSignUpNext(user),
    error: (error: any) => this.onSignUpError(error),
    complete: () => this.onSignUpComplete(),
  };

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  datenschutz = false;
  agrement = false;
  update = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private statusService: StatusService,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.userEmails.controls['token'].setValue(params['token']);

      const token = jwtDecode<Token>(params['token']);

      this.userEmails.controls['username'].setValue(token.username);
      this.userEmails.controls['firstname'].setValue(token.firstname);
      this.userEmails.controls['lastname'].setValue(token.lastname);
    });

    this.statusService.currentStatus.subscribe((status: boolean) => {
      this.update = status;
    });
  }

  get username() {
    return this.userEmails.get('username');
  }

  get firstname() {
    return this.userEmails.get('firstname');
  }

  get lastname() {
    return this.userEmails.get('lastname');
  }

  get password() {
    return this.userEmails.get('password');
  }

  get confirm() {
    return this.userEmails.get('confirm');
  }

  signup() {
    this.submitBtnState = ClrLoadingState.LOADING;

    this.errorSignup = false;
    this.signupErrorResponse = new HttpErrorResponse({});

    const user = {
      Username: this.userEmails.value.username,
      Firstname: this.userEmails.value.firstname,
      Lastname: this.userEmails.value.lastname,
      Datenschutz: this.userEmails.value.datenschutz,
      Password: this.userEmails.value.password,
      Token: this.userEmails.value.token,
      Url: this.userEmails.value.url,
    };

    this.authService.signup(user).subscribe(this.signupOberserver);
  }

  onSignUpNext(user: User) {
    this.submitBtnState = ClrLoadingState.SUCCESS;

    this.multifactor = true;
  }

  onSignUpError(httpErrorResponse: HttpErrorResponse) {
    this.submitBtnState = ClrLoadingState.ERROR;

    this.errorSignup = true;
    this.signupErrorResponse = httpErrorResponse;
  }

  onSignUpComplete() {
    this.submitBtnState = ClrLoadingState.DEFAULT;
  }

  onDatenschutzOkay() {
    this.userEmails.get('datenschutz')?.enable();
    this.userEmails.get('datenschutz')?.setValue(true);
    this.datenschutz = false;
    this.agrement = true;
  }

  onDatenschutzCancel() {
    // this.userEmails.get('datenschutz')?.disable();
    this.userEmails.get('datenschutz')?.setValue(false);
    this.datenschutz = false;
    this.agrement = false;
  }

  onDatenschutzReadAndAgree() {
    this.datenschutz = true;
  }

  onUpdate() {
    document.location.reload();
  }
}
