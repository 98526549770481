import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Station } from 'src/app/models/Station';

export const StationActions = createActionGroup({
  source: 'Station',
  events: {
    'Load Stations': emptyProps(),
    'Load Stations Success': props<{ stations: Station[] }>(),
    'Load Stations Failure': props<{ error: HttpErrorResponse }>(),

    'Load Station': props<{ uid: string }>(),
    'Load Station Success': props<{ station: Station }>(),
    'Load Station Failure': props<{ error: HttpErrorResponse }>(),
  },
});
