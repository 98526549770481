import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RisksRoutingModule } from './risks-routing.module';
import { RisksListGridComponent } from './risks-list-grid/risks-list-grid.component';
import { RisksReviewComponent } from './risks-review/risks-review.component';
import { DialogAddImageComponent } from './dialog-add-image/dialog-add-image.component';
import { DialogAddCabinetComponent } from './dialog-add-cabinet/dialog-add-cabinet.component';
import { DialogImportCabinetComponent } from './dialog-import-cabinet/dialog-import-cabinet.component';
import { RiskTabCabinetComponent } from './risks-tab-cabinet/risks-tab-cabinet.component';
import { RiskTabResultsComponent } from './risks-tab-results/risks-tab-results.component';
import { RiskTabEvaluationComponent } from './risks-tab-evaluation/risks-tab-evaluation.component';
import { RiskTabCabinetImagesComponent } from './risks-tab-images-cabinet/risks-tab-images-cabinet.component';
import { RiskTabEvaluationImagesComponent } from './risks-tab-images-evaluation/risks-tab-images-evaluation.component';
import { RiskTabEditTemplateComponent } from './risks-tab-template/risks-tab-template.component';
import { RiskListFilterComponent } from './risk-list-filter/risks-list-filter.component';
import { RiskEvaluationComponent } from './risks-evaluations-overview/risks-evaluations-overview.component';
import { RisksEditEvaluationComponent } from './risks-evaluation-edition/risks-evaluation-edition.component';
import { RisksCabinetTableComponent } from './risks-cabinet-table/risks-cabinet-table.component';
import { RisksEditCabinetComponent } from './risks-cabinet-edition/risks-cabinet-edition.component';
import { RisksEditTemplateComponent } from './risks-template-edition/risks-template-edition.component';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  hostGroupIcon,
  hostIcon,
  minusCircleIcon,
  plusCircleIcon,
  dotCircleIcon,
  infoCircleIcon,
  infoStandardIcon,
  syncIcon,
  eraserIcon,
  trashIcon,
  cogIcon,
  imageIcon,
  eyeIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  hostGroupIcon,
  hostIcon,
  plusCircleIcon,
  minusCircleIcon,
  dotCircleIcon,
  infoCircleIcon,
  infoStandardIcon,
  syncIcon,
  eraserIcon,
  trashIcon,
  imageIcon,
  eyeIcon,
  cogIcon,
);

@NgModule({
  declarations: [
    RisksListGridComponent,
    DialogAddImageComponent,
    DialogAddCabinetComponent,
    DialogImportCabinetComponent,
    RisksReviewComponent,
    RiskTabCabinetComponent,
    RiskTabEvaluationComponent,
    RiskTabCabinetImagesComponent,
    RiskTabEvaluationImagesComponent,
    RiskTabResultsComponent,
    RiskTabEditTemplateComponent,
    RiskListFilterComponent,
    RiskEvaluationComponent,
    RisksEditEvaluationComponent,
    RisksCabinetTableComponent,
    RisksEditCabinetComponent,
    RisksEditTemplateComponent,
  ],
  imports: [
    RisksRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    ClarityModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class RisksModule {}
