import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { TelemetryActions } from './telemetry.actions';
import { TelemetryStoreService } from 'src/app/services/telemetry-store.service';

@Injectable()
export class TelemetryEffects {
  private service = inject(TelemetryStoreService);

  loadTelemetries$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelemetryActions.loadTelemetries),
      switchMap(() =>
        this.service.getAll().pipe(
          map((telemetries) =>
            TelemetryActions.loadTelemetriesSuccess({
              telemetries: telemetries,
            }),
          ),
          catchError((error) =>
            of(TelemetryActions.loadTelemetriesFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadTelemetry$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelemetryActions.loadTelemetry),
      mergeMap((action) =>
        this.service.getSingle(action.uid).pipe(
          map((station) =>
            TelemetryActions.loadTelemetrySuccess({ telemetry: station }),
          ),
          catchError((error) =>
            of(TelemetryActions.loadTelemetryFailure({ error })),
          ),
        ),
      ),
    );
  });
}
