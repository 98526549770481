<div class="card">
  <h3 class="card-header">
    <cds-icon shape="details" size="md"></cds-icon>&nbsp;Messwerte -
    {{ telemetry.MQTT.Identifier }}
    <span style="float: right">
      <cds-icon shape="line-chart" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <div class="card-text">
      <div
        (chartInit)="onChartInit($event)"
        #archiveChart
        echarts
        [initOpts]="initOpts"
        [options]="chartOption"
      ></div>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
