import { createReducer, on } from '@ngrx/store';
import { Telecontrol } from 'src/app/models/Telecontrol';
import { TelecontrolActions } from './telecontrol.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

export const telecontrolFeatureKey = 'telecontrol';

export const adapter = createEntityAdapter<Telecontrol>({
  selectId: (telecontrol) => telecontrol.Uid,
});

export interface State extends EntityState<Telecontrol> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(TelecontrolActions.loadTelecontrols, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(TelecontrolActions.loadTelecontrolsSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.telecontrols, state),
      loading: false,
      error: null,
    };
  }),

  on(TelecontrolActions.loadTelecontrolsFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  on(TelecontrolActions.loadTelecontrol, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(TelecontrolActions.loadTelecontrolSuccess, (state, action): State => {
    return {
      ...adapter.upsertOne(action.telecontrol, state),
      loading: false,
      error: null,
    };
  }),

  on(TelecontrolActions.loadTelecontrolFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
