<!-- <button clrTabLink>Fotos</button> -->

<!-- <form clrForm [formGroup]="formMobile"> -->
<br />

<button class="btn btn-outline" (click)="onAddFileClick()" [disabled]="!online">
  <cds-icon shape="plus-circle"></cds-icon> hinzufügen
</button>

<div>
  <table class="table">
    <thead>
      <tr>
        <th class="left">Name</th>
        <th>Vorschau</th>
        <th>Optionen</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let img of images$; let i = index"> -->
      <tr *ngFor="let img of cabinet?.Images; let i = index">
        <td class="left">
          <a [routerLink]="[]" role="button" (click)="showImage(img)">
            {{ img.Name }}
          </a>
        </td>
        <td>
          <img
            [src]="getThumbImage(img)"
            width="40px"
            alt="Vorschau"
            role="button"
            (click)="showImage(img)"
          />
        </td>
        <td>
          <!-- &nbsp;<button
            class="btn btn-icon btn-primary"
            title="speichern"
            (click)="onUpdateFile(img)"
          >
            <cds-icon shape="sync"></cds-icon>
          </button> -->
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="löschen"
            (click)="onDeleteFile(img)"
          >
            <cds-icon shape="trash" />
          </button>
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="als Vorschau"
            [disabled]="img.AsPreview"
            (click)="onSetPreviewImg(img)"
          >
            <cds-icon shape="image" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- </form> -->

<!-- popup-windows -->

<!-- <app-dialog-add-image-cabinet
  #imgDialog
  *ngIf="dialogAddFile"
  [cabinet]="cabinet"
  (isDialogOpen)="onDialogAddFileClosing()"
  (updated)="onUpdateFile($event)"
></app-dialog-add-image-cabinet> -->
<app-dialog-add-image
  #imgDialog
  *ngIf="dialogAddFile"
  (isDialogOpen)="onDialogAddFileClosing()"
  (updated)="onUpdateFile($event)"
  (initializied)="setDialog($event)"
></app-dialog-add-image>

<clr-modal [(clrModalOpen)]="dialogViewImg" clrModalSize="xl">
  <div class="modal-body">
    <img [src]="imgViewSrc" alt="ANZEIGE" style="align-self: center" />
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="dialogViewImg = false"
    >
      Ok
    </button>
  </div>
</clr-modal>
<!-- <app-dialog-import-cabinet
  *ngIf="dialogAddFile"
  (basic)="onDialogAddFileClick()"
  [template$]="template$"
></app-dialog-import-cabinet> -->
