<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> /
        <a routerLink="/risks">Gefährdungsbeurteilung</a> /
        <a routerLink="/risks/review/{{ cabinet?._id }}">Anlage</a> / Daten
        Beurteilung
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-block">
    <h4 class="card-title">Anlage</h4>
    <div class="card-text">
      <!-- ANLAGE -->
      <app-risks-cabinet-table [cabinet]="cabinet" />
    </div>
    <br />
    <br />
    <h4 class="card-title">Bewertung</h4>
    <form
      clrForm
      class="clr-col-12 clr-col-md-12"
      [formGroup]="formEvaName"
      (ngSubmit)="onSubmitEvaName($event)"
    >
      <table style="width: 30%">
        <tr>
          <th style="width: 80%">
            <!-- <span style="float: left"> -->
            <clr-input-container id="inputContainer">
              <label for="inputContainer"> Name </label>
              <input
                clrInput
                style="width: 100%"
                [formControlName]="'evaluationName'"
              />
              <!-- [ngModel]="evaluation.Name" -->
            </clr-input-container>
          </th>
          <th style="width: 20%">
            <button type="submit" class="btn btn-outline" style="right: 0%">
              Übernehmen
            </button>
          </th>
        </tr>
      </table>
    </form>

    <button
      *ngIf="online && isManager$"
      class="btn btn-primary"
      (click)="newEvaluationClick()"
      [disabled]="formDisabled() || cabinet?._id?.startsWith('offline')"
      style="float: right"
    >
      Bewertung abgeschlossen
    </button>

    <!-- <div class="card-text"> -->
    <clr-tabs>
      <!-- PRÜFUNG -->
      <clr-tab>
        <button clrTabLink>Prüfung</button>
        <clr-tab-content *clrIfActive>
          <app-risks-tab-evaluation
            #pruefungTab
            [tabFor$]="'Pruefung'"
            [disabled]="formDisabled()"
            [evaluation]="evaluation"
            (updated)="refresh($event)"
            (init)="initPruefung($event)"
          ></app-risks-tab-evaluation>
        </clr-tab-content>
      </clr-tab>

      <!-- GEFÄHRDUNGEN -->
      <clr-tab>
        <button clrTabLink>Gefährdung</button>
        <clr-tab-content *clrIfActive>
          <app-risks-tab-evaluation
            #gefaehrdungTab
            [tabFor$]="'Gefaehrdung'"
            [evaluation]="evaluation"
            [disabled]="formDisabled()"
            (updated)="refresh($event)"
            (init)="initGefaehrdung($event)"
          ></app-risks-tab-evaluation>
        </clr-tab-content>
      </clr-tab>
      <!-- FOTOS -->
      <clr-tab>
        <button clrTabLink>Fotos</button>
        <clr-tab-content *clrIfActive>
          <app-risks-tab-images-evaluation
            #imgTab
            [evaluation]="evaluation"
            [disabled]="formDisabled()"
            (updated)="imgSet($event)"
            (init)="initFiles($event)"
          ></app-risks-tab-images-evaluation>
        </clr-tab-content>
      </clr-tab>
      <!-- Auswertung -->
      <clr-tab *ngIf="hasResult()">
        <button clrTabLink>Bewertung</button>
        <clr-tab-content *clrIfActive>
          <app-risks-tab-results
            #imgTab
            (updated)="imgSet($event)"
            (init)="initAuswertung($event)"
          ></app-risks-tab-results>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<!-- Images-Dialog -->
<clr-modal [(clrModalOpen)]="images">
  <h3 class="modal-title">{{ text | uppercase }}</h3>
  <div class="modal-body">
    <cds-icon
      shape="angle"
      direction="left"
      size="xl"
      (click)="imgBackwards()"
      class="btn btn-primary"
      style="position: absolute; left: 10px; top: 50%"
    ></cds-icon>
    <img [src]="imgViewSrc" alt="ANZEIGE" style="position: center" />
    <cds-icon
      shape="angle"
      direction="right"
      size="xl"
      (click)="imgForwards()"
      class="btn btn-primary"
      style="position: absolute; right: 10px; top: 50%"
    ></cds-icon>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="images = false">
      Ok
    </button>
  </div>
</clr-modal>

<!-- Result-Dialog-->
<clr-modal [(clrModalOpen)]="onNewEvaluation">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message">
    <app-risks-tab-results
      [evaluation]="evaluation"
      (updated)="refresh($event)"
      (init)="initAuswertung($event)"
    >
    </app-risks-tab-results>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onNewEvaluation = false"
    >
      Ok
    </button>
  </div>
</clr-modal>

<!-- Info-Dialog -->
<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
