import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  inject,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-dialog-import-cabinet',
  templateUrl: './dialog-import-cabinet.component.html',
  styleUrls: ['./dialog-import-cabinet.component.css'],
})
export class DialogImportCabinetComponent implements OnInit {
  @Output() basic = new EventEmitter<boolean>();
  @Input() template$ = new Observable<Template>();

  template = {} as Template;
  cabinet$ = new Observable<Cabinet>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  uploadProgress = 0;
  uploadSub$ = new Subscription();
  excelFile: File | null = null;

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  formMobile = this.formBuilder.group({
    ExcelFile: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
  });

  constructor(private service: AssessmentStoreService) {}

  ngOnInit() {
    this.template$.subscribe((template) => {
      this.template = template;
      template.Anlage?.map((anlage) => {
        this.formDynamisch.addControl(
          anlage._id || '',
          this.formBuilder.control(''),
        );
      });
    });
  }

  onSubmitExcelFile() {
    const form = this.formMobile.getRawValue();
    if (this.excelFile) {
      this.service
        .postCabinetExcelFile(
          this.excelFile,
          form.ExcelFile.Name,
          this.template,
        )
        .subscribe(() => {
          this.basic.emit(true);
          this.resetUpload();
        });
    }
  }

  onExcelImportSelected(event: any) {
    this.excelFile = event.target.files[0];
  }

  onCancelUpload() {
    this.uploadSub$.unsubscribe();
    this.resetUpload();
    this.closeDialog();
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub$.unsubscribe();
  }

  openChange(value: boolean) {
    this.basic.emit(false);
  }

  closeDialog() {
    this.basic.emit(false);
  }
}
