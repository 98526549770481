<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        Fernwirktechnik
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedTelecontrols$ | async as combinedTelecontrols">
      <div *ngFor="let telecontrol of combinedTelecontrols">
        <app-telecontrols-messages-grid-card
          [telecontrol]="telecontrol"
        ></app-telecontrols-messages-grid-card>
      </div>
    </div>
  </div>
</div>
