import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContract from './contract.reducer';
import { Contract } from 'src/app/models/Contract';

const { selectAll } = fromContract.adapter.getSelectors();

export const selectContractState = createFeatureSelector<fromContract.State>(
  fromContract.contractFeatureKey,
);

export const selectContracts = createSelector(selectContractState, selectAll);

export const selectContract = (uid: string) =>
  createSelector(
    selectContracts,
    (contracts) =>
      contracts.find((contract) => contract.Uid === uid) || ({} as Contract),
  );

export const selectContractsLoading = createSelector(
  selectContractState,
  (state) => state.loading,
);

export const selectContractsError = createSelector(
  selectContractState,
  (state) => state.error,
);

export const selectCombinedContracts = createSelector(
  selectContracts,
  selectContractsLoading,
  selectContractsError,
  (contracts, loading, error) => ({
    contracts,
    loading,
    error,
  }),
);

export const selectCombinedContract = (uid: string) =>
  createSelector(
    selectContract(uid),
    selectContractsLoading,
    selectContractsError,
    (contract, loading, error) => ({
      contract,
      loading,
      error,
    }),
  );
