import { Component, Injectable, Input, OnInit } from '@angular/core';
import { Telecontrol } from 'src/app/models/Telecontrol';
import { MeasureStoreService } from 'src/app/services/measurearchive-store';

@Injectable()
@Component({
  selector: 'app-measure-archive-table',
  templateUrl: './measure-archive-table.component.html',
  styleUrls: ['./measure-archive-table.component.css'],
})
export class MeasureArchiveTableComponent implements OnInit {
  @Input() telemetry = {
    MQTT: {
      Enabled: false,
      Identifier: '0',
      Username: 'name',
      Password: 'pw',
    },
  } as Telecontrol;

  @Input() public refreshArchiveTable(): void {
    this.archivedTelemetries$ = this.measureStoreService.getAll();

    let counter = 0;
    this.archivedTelemetries$.subscribe({
      next: (value) => {
        value.forEach(() => {
          counter++;
        });
      },
      complete: () => {
        console.log(`Counted ${counter} entries....`);
      },
    });
  }

  public archivedTelemetries$ = this.measureStoreService.getAll();

  constructor(private measureStoreService: MeasureStoreService) {
    this.archivedTelemetries$ = this.measureStoreService.getAll();
  }

  ngOnInit() {
    this.refreshArchiveTable();
  }
}
